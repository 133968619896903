<h2 mat-dialog-title>
    {{data?.id ? 'Editar Tarefa': 'Nova Tarefa'}}
</h2>

<mat-dialog-content>

    <form [formGroup]="tarefaForm" class="flex flex-col">

        <mat-form-field appearance="fill">
            <mat-label>Título</mat-label>
            <input matInput formControlName="title" placeholder="Enter title">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status_id">
                <mat-option [value]="1">A Fazer</mat-option>
                <mat-option [value]="2">Em Progresso</mat-option>
                <mat-option [value]="3">Concluído</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Designar para</mat-label>
            <mat-select formControlName="assignees_ids" multiple>
                @for(person of people; track $index){
                <mat-option [value]="person.id">{{ getShortName(person.name) }}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Descrição</mat-label>
            <textarea matInput formControlName="description" placeholder="Enter description"></textarea>
        </mat-form-field>

        <mat-form-field class="example-form-field">
            <mat-label>Tags</mat-label>
            <mat-chip-grid #reactiveChipGrid aria-label="Enter reactive form keywords" formControlName="tags">
            @for (keyword of reactiveKeywords(); track keyword) {
              <mat-chip-row (removed)="removeReactiveKeyword(keyword)">
                {{keyword}}
              <button matChipRemove aria-label="'remove reactive form' + keyword">
                <mat-icon>cancel</mat-icon>
              </button>
              </mat-chip-row>
            }
            </mat-chip-grid>
            <!-- placeholder="New keyword..." -->
            <input
              [matChipInputFor]="reactiveChipGrid"
              (matChipInputTokenEnd)="addReactiveKeyword($event)"
            />
          </mat-form-field>

    </form>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
    <button mat-raised-button [disabled]="tarefaForm.invalid || tarefasService.isLoading()"
        (click)="onSubmit()">Salvar</button>
</mat-dialog-actions>