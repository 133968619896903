import {
  Component,
  inject,
  input,
  model,
  OnInit,
  output,
  ViewChild,
} from '@angular/core';
import { planejamento, PlanejamentoService } from './planejamento.service';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { STAGES } from '../../components/turma-dialog/turma-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth.service';
import { MobileQueryService } from '../../services/mobile-query.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-planejamento',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    NgClass,
    FormsModule,
  ],
  templateUrl: './planejamento.component.html',
  styleUrl: './planejamento.component.scss',
})
export class PlanejamentoComponent implements OnInit {
  edition = model<number>(2);
  estagio = model<number>(1);

  displayedColumns: string[] = ['paragrafo', 'materia', 'reading', 'dictation'];

  private authService = inject(AuthService);
  protected planejamentoService = inject(PlanejamentoService);
  private snackBar = inject(MatSnackBar);
  protected mobileQueryService = inject(MobileQueryService);

  editable = input<boolean>(true);

  planejamentoSelected = output<planejamento>();

  readonly estagios = STAGES;

  private route = inject(ActivatedRoute);
  private router = inject(Router);

  ngOnInit(): void {
    const edtionParam = this.route.snapshot.queryParams['edition'];
    const estagioParam = this.route.snapshot.queryParams['estagio'];

    if (edtionParam) this.edition.set(Number(edtionParam));
    if (estagioParam) this.estagio.set(Number(estagioParam));

    const rolesAllowToEdit = [
      this.authService.roles.Adm,
      this.authService.roles.Coordenador,
    ];

    if (this.editable()) {
      this.displayedColumns = ['index', ...this.displayedColumns];
    }

    if (this.authService.hasRole(rolesAllowToEdit) && this.editable()) {
      this.displayedColumns.push('actions');
    }

    if (this.mobileQueryService.matches) {
      this.displayedColumns = ['materia', 'reading', 'dictation'];
    }

    this.getPlanejamento();
  }

  getPlanejamento() {
    this.planejamentoService.getByFilters({
      estagio: this.estagio(),
      edition: this.edition(),
    });

    this.router.navigate([], {
      queryParams: { estagio: this.estagio(), edition: this.edition() },
    });
  }

  upsert(item: planejamento) {
    item.editMode = false;

    this.planejamentoService.upsert(item).subscribe((response) => {

      if(!item.id) item.id = response.id;

      this.snackBar.open('Planejamento atualizado!');
    });
  }

  insertItem(
    targetItem: planejamento,
    position: 'above' | 'below'
  ): planejamento[] {
    const newItem: any = {
      materia: '',
      edition: this.edition(),
      estagio: this.estagio(),
      editMode: true,
      paragrafo: '',
      reading: '',
      dictation: '',
    };

    const array: planejamento[] = this.planejamentoService.dataSource.data;

    const index = array.findIndex((item) => item.id === targetItem.id);

    // If the item is not found, return the original array
    if (index === -1) return array;

    // Calculate the position to insert
    const insertIndex = position === 'above' ? index : index + 1;

    newItem.order =
      position === 'above' ? targetItem.order : targetItem.order + 1;

    // Insert the new item at the desired index
    this.planejamentoService.dataSource.data = [
      ...array.slice(0, insertIndex),
      newItem,
      ...array.slice(insertIndex),
    ];

    console.log(newItem);

    return this.planejamentoService.dataSource.data;
  }

  deleteItem(item: planejamento, index: number) {
    this.planejamentoService.delete(item).subscribe(() => {
      this.snackBar.open('Planejamento atualizado!');
    });

    this.removeFromArray(index);
  }

  removeFromArray(index: number): planejamento[] {
    if (index < 0 || index >= this.planejamentoService.dataSource.data.length)
      return this.planejamentoService.dataSource.data;

    // Return a new array without the item at the specified index
    this.planejamentoService.dataSource.data = [
      ...this.planejamentoService.dataSource.data.slice(0, index),
      ...this.planejamentoService.dataSource.data.slice(index + 1),
    ];

    return this.planejamentoService.dataSource.data;
  }

  selectedRow?: planejamento;

  selectRow(row: planejamento) {
    if (this.editable()) return;

    this.selectedRow = row;
    this.planejamentoSelected.emit(row);
  }
}
