@if(paginatedService.notFound){
<app-not-found></app-not-found>
}
@else if(paginatedService.dataSource.data.length == 0){
<app-loading-screen></app-loading-screen>
}
@else if(!paginatedService.searchFilter && !authService.hasRole(authService.roles.Comercial)){
<app-teacher-dashboard></app-teacher-dashboard>
}

<div class="card w-full relative" [ngClass]="{'hidden': paginatedService.dataSource.data.length == 0}">

  <div
    class="h-full w-full justify-center items-center absolute top-0 left-0 z-10 bg-black bg-opacity-5 rounded-default"
    [ngClass]="{'hidden': !paginatedService.isLoading, 'flex': paginatedService.isLoading}">
    <app-loading [shape]="'circle'"></app-loading>
  </div>

  <table mat-table [dataSource]="paginatedService.dataSource" class="table !shadow-none">

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>
        <mat-icon>school</mat-icon>
        <span class="max-md:hidden">Turma</span>
        <span class="md:hidden">Turmas</span>
      </th>
      <td mat-cell *matCellDef="let turma"> {{turma.name}} </td>
    </ng-container>

    <ng-container matColumnDef="unidade">
      <th mat-header-cell *matHeaderCellDef>

        @if(authService.hasRole(authService.roles.Professor)){
        <mat-icon>place_on</mat-icon>
        Unidade
        }
        @else {
        <app-table-filter [paginatedService]="paginatedService" [paginatedComponent]="this" [options]="schoolUnits"
          label="Unidade" name="school"></app-table-filter>
        }

      </th>
      <td mat-cell *matCellDef="let turma"> 
        {{turma.unit}}
      </td>
    </ng-container>

    <ng-container matColumnDef="horario">
      <th mat-header-cell *matHeaderCellDef class="max-md:!text-right">

        @if(authService.hasRole(authService.roles.Professor)){
        <mat-icon class="mat-18 max-md:!hidden">schedule</mat-icon>
        Horário
        }
        @else {
        <app-table-filter [paginatedService]="paginatedService" [paginatedComponent]="this" [options]="slotTimes"
          label="Horário" name="timeSlot"></app-table-filter>
        }

      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:!text-right">
        {{turma.timeslot}}
      </td>
    </ng-container>

    <ng-container matColumnDef="teacher">
      <th mat-header-cell *matHeaderCellDef>


        <app-table-filter [paginatedService]="paginatedService" [paginatedComponent]="this" [options]="teachers"
          label="Teacher" name="teacher"></app-table-filter>

      </th>
      <td mat-cell *matCellDef="let turma">
        {{getShortName(turma.teacherName)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="estagio">
      <th mat-header-cell *matHeaderCellDef>

        @if(authService.hasRole(authService.roles.Professor)){
        <mat-icon>menu_book</mat-icon>
        Estágio
        }
        @else {
        <app-table-filter [paginatedService]="paginatedService" [paginatedComponent]="this" [options]="stages"
          label="Estágio" name="stage"></app-table-filter>
        }

      </th>
      <td mat-cell *matCellDef="let turma">
        {{turma.stage ?? '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="formato">
      <th mat-header-cell *matHeaderCellDef>
        <mat-icon>domain</mat-icon>
        Formato
      </th>
      <td mat-cell *matCellDef="let turma">
        {{turma.format}}
      </td>
    </ng-container>

    <ng-container matColumnDef="alunos">
      <th mat-header-cell *matHeaderCellDef>

        @if(authService.hasRole(authService.roles.Professor)){
        <mat-icon>people</mat-icon>
        Alunos
        }
        @else {
        <app-table-filter [paginatedService]="paginatedService" [paginatedComponent]="this"
          [options]="studentsCountFilter" label="Alunos" name="studentCount"></app-table-filter>
        }

      </th>
      <td mat-cell *matCellDef="let turma">

        <div class="flex flex-row gap-2 items-center">

          @if(turma.studentCount>= 6 || turma.name.toLowerCase().includes("vip")){
          <div class="status-dot status-dot-3"></div>
          }
          @else if(turma.studentCount >= 4) {
          <div class="status-dot status-dot-4"></div>
          }
          @else {
          <div class="status-dot status-dot-5"></div>
          }

          <span>
            {{ (turma.studentCount<10?'0':'') + turma.studentCount}} </span>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>

        @if(authService.hasRole(authService.roles.Professor)){
        Status
        }
        @else {
        <app-table-filter [paginatedService]="paginatedService" [paginatedComponent]="this"
          [options]="classroomStatuses" label="Status" name="status"></app-table-filter>
        }

      </th>
      <td mat-cell *matCellDef="let turma">
        <chip [color]="getTurmaStatusColor(turma.statusId)">
          {{enumToString(eTurmaStatus, turma.statusId)}}
        </chip>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'hidden': !canEdit()}"></th>
      <td mat-cell *matCellDef="let turma" [ngClass]="{'hidden': !canEdit()}" class="max-md:!pr-1">

        <button mat-icon-button class="!overflow-hidden !rounded-full float-right" [matMenuTriggerFor]="options"
          (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #options="matMenu">
          <button mat-menu-item (click)="edit(turma)">
            <mat-icon>edit</mat-icon>
            <span class="font-normal text-light">Editar</span>
          </button>
        </mat-menu>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let turma; columns: displayedColumns;" [routerLink]="['/turma', turma.id]"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="!mobileQueryService.matches" [hidePageSize]="true">
  </mat-paginator>

</div>