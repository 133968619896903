import { Component, inject } from '@angular/core';
import { BasePaginatedComponent } from '../../abstracts/base-paginated.component';
import { AlunosService } from './alunos.service';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { MatTableModule } from '@angular/material/table';
import { NgClass } from '@angular/common';
import { LoadingComponent } from '../../components/loading/loading.component';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { MobileQueryService } from '../../services/mobile-query.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { ChipComponent } from '../../components/chip/chip.component';

@Component({
  selector: 'app-alunos',
  standalone: true,
  imports: [
    LoadingScreenComponent,
    MatTableModule,
    NgClass,
    LoadingComponent,
    ProfilePictureComponent,
    MatPaginatorModule,
    NotFoundComponent,
    RouterModule,
    ChipComponent
  ],
  templateUrl: './alunos.component.html',
  styleUrl: './alunos.component.scss',
})
export class AlunosComponent extends BasePaginatedComponent {
  protected override paginatedService = inject(AlunosService);
  protected mobileQueryService = inject(MobileQueryService);

  desktopColumns: string[] = [
    'name',
    'school',
    'classroom',
    'status'
  ];

  mobileColumns: string[] = ['name'];
}
