import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MessageDialogComponent,
  messageDialogData,
} from '../components/message-dialog/message-dialog.component';
import confetti from 'canvas-confetti';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private readonly dialog = inject(MatDialog);

  // Overload signatures
  alert(message: string): void;
  alert(message: messageDialogData): void;

  // Implementation that handles both overloads
  alert(message: string | messageDialogData) {
    let messageToShow: string;
    let title: string |  undefined;

    if (typeof message === 'string') {
      messageToShow = message;
    } else {
      messageToShow = message.message;
      title = message.title;
    }

    this.dialog.open(MessageDialogComponent, {
      data: {
        message: messageToShow,
        title
      },
    });
  }

  celebrate() {
    const duration = 6000; // in milliseconds

    confetti({
      particleCount: 500,
      spread: 1000,
    });

    // Clear confetti after a certain duration
    setTimeout(() => confetti.reset(), duration);
  }
}
