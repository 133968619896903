import {
  Component,
  inject,
  input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { LogomarkComponent } from '../logomark/logomark.component';
import { MatSidenav } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  NavigationExtras,
  Router,
  RouterModule,
} from '@angular/router';
import { NgTemplateOutlet } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutoFocusDirective } from '../../directives/auto-focus.directive';
import { NotificationService } from '../../services/notification.service';
import { NotificationsComponent } from '../../views/notifications/notifications.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialog } from '@angular/material/dialog';
import { ProfilePictureUploadComponent } from '../profile-picture-upload/profile-picture-upload.component';
import { ApiService } from '../../services/api.service';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    LogomarkComponent,
    RouterModule,
    NgTemplateOutlet,
    FormsModule,
    AutoFocusDirective,
    NotificationsComponent,
    MatBadgeModule,
    ProfilePictureComponent
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent implements OnInit, OnDestroy {
  protected authService = inject(AuthService);
  private router = inject(Router);

  @ViewChild('notifications') protected notificationsMenu!: MatMenu;

  private route = inject(ActivatedRoute);
  protected notificationService = inject(NotificationService);
  protected apiService = inject(ApiService)

  readonly dialog = inject(MatDialog);

  autofocus: boolean = false;
  search: string = '';
  searchSubscription?: Subscription;

  showSearchInputOnMobile = !!this.route.snapshot.queryParams['search'];
  snav = input.required<MatSidenav>();

  firstName() {
    return this.authService.currentUser.name?.split(' ')[0];
  }

  ngOnInit(): void {
    this.notificationService.countUnread().subscribe();

    if (!this.searchSubscription)
      this.searchSubscription = this.route.queryParams.subscribe((params) => {
        this.search = params['search'];
        this.showSearchInputOnMobile = !!this.search;
      });
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
  }

  onSearch() {
    let navigationExtras: NavigationExtras = {};

    const routeData = this.route.snapshot.firstChild?.data;

    const currentRouteSearchable = routeData?.['searchable'] === true;

    let searchIn: Array<string> = [];

    if (currentRouteSearchable && routeData?.['searchIn'])
      searchIn.push(routeData['searchIn']);

    if (this.search.trim())
      navigationExtras.queryParams = { search: this.search.trim() };
    else navigationExtras.queryParams = { clearSearch: true };

    this.router.navigate(
      currentRouteSearchable ? searchIn : [''],
      navigationExtras
    );
  }

  openProfilePictureUploadDialog() {
    this.dialog.open(ProfilePictureUploadComponent);
  }
}
