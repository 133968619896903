<h1 matDialogTitle>Ocorrência</h1>

<mat-dialog-content>

    <div class="flex flex-col gap-4">

        <app-profile-picture [user]="data.student"></app-profile-picture>
        
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>Descrição</mat-label>
            <textarea matInput rows="3" [(ngModel)]="description"></textarea>
        </mat-form-field>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose>Cancelar</button>
    <button mat-flat-button [disabled]="!description() || apiService.isLoading" (click)="onSave()">Salvar</button>
</mat-dialog-actions>