<div class="h-full flex flex-col">

    <div class="flex flex-row justify-end gap-5">

        <mat-form-field appearance="outline">
            <mat-label>Unidade</mat-label>
            <mat-select [(ngModel)]="schoolId" (ngModelChange)="getTimesheets()">
                @for (unidade of unidades; track unidade) {
                <mat-option [value]="unidade.id">{{unidade.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" (click)="picker.open()">
            <mat-label>Mês e ano</mat-label>
            <input matInput [formControl]="date" [readonly]="true" (ngModelChange)="getTimesheets()">
            <input matInput [matDatepicker]="picker" class="!hidden">
            <button matSuffix mat-icon-button class="mr-2">
                <mat-icon>today</mat-icon>
            </button>
            <mat-datepicker #picker startView="year" (monthSelected)="setMonthAndYear($event, picker)"></mat-datepicker>
        </mat-form-field>

    </div>

    <div class="basis-full">
        @if (apiService.isLoading) {
        <app-loading-screen></app-loading-screen>
        }
        @else if(notFound){
        <app-not-found></app-not-found>
        }
        @else {
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
            @for(teacher of timesheet; track teacher){
            <button class="card card-clickable text-left p-5" (click)="openTimesheetDialog(teacher)">
                <app-profile-picture [user]="{ id: teacher.userId, name: teacher.userName }" size="big"
                    [shortenName]="true">
                    @if(teacher.expectedHours){
                    <p class="text-sm slide-in text-light">
                        {{teacher.expectedHours}} horas previstas
                    </p>
                    }
                    @else {
                    <p class="text-sm">&nbsp;</p>
                    }
                    <h6 style="font-size: 1rem; color: var(--green)">
                        <mat-icon class="align-middle">trending_up</mat-icon>
                        {{teacher.hoursCount}} horas
                    </h6>
                </app-profile-picture>
            </button>
            }
        </div>
        }
    </div>

</div>