import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

export const InterceptorService: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const snackBar = inject(MatSnackBar); // Use the new 'inject' function for dependency injection

  const cookieService = inject(CookieService);

  // Get token from local storage (or a service)
  const token = cookieService.get('access_token');

  // Clone the request to add the authorization header
  let modifiedReq = req;
  
  const isApiV1 = modifiedReq.url.includes('api/v1');

  if (token && !isApiV1) {
    modifiedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // Handle the request and catch errors
  return next(modifiedReq).pipe(
    catchError((error: HttpErrorResponse) => {
      // Show the snackbar for errors

      let errorMsg = error.error.error;

      if (!errorMsg) errorMsg = error.error.msg;
      if (!errorMsg) errorMsg = error.error.message;
      if (!errorMsg) errorMsg = 'Houve um erro';

      const isCheckLogin =
        typeof errorMsg == 'string' &&
        errorMsg.includes('<JWT>') &&
        modifiedReq.url.includes('check_login');

      if (!isCheckLogin)
        snackBar.open(errorMsg, 'ok');

      // Re-throw the error
      return throwError(() => error);
    })
  );
};
