@if(notFound){
<app-not-found></app-not-found>
}
@else if(!aluno){
<app-loading-screen></app-loading-screen>
}

<div class="card p-5 flex flex-col gap-5" [ngClass]="{'hidden': !aluno}">

    @if(aluno){
    <div>
        <app-profile-picture [user]="aluno" [size]="'big'"></app-profile-picture>
    </div>
    }

    <mat-tab-group>

        <mat-tab label="Perfil">
            <app-pessoa-form #alunoForm></app-pessoa-form>
        </mat-tab>

        @if(!aluno || aluno.responsavelId){
        <mat-tab label="Responsável">
            <app-pessoa-form #respForm></app-pessoa-form>
        </mat-tab>
        }

        <mat-tab label="Ocorrências">

            @for(ocorrencia of ocorrencias; track ocorrencia){
            <p class="py-3">
                {{ocorrencia.description}}
            </p>
            <hr>
            }
            @empty{
            <p class="py-3">
                Não há ocorrências
            </p>
            }
        </mat-tab>

        <!-- <mat-tab label="Financeiro">
            Não há ocorrências
        </mat-tab> -->

    </mat-tab-group>

</div>