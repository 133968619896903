import { Component } from '@angular/core';
import { LogomarkComponent } from '../logomark/logomark.component';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-loading-screen',
  standalone: true,
  imports: [LogomarkComponent, LoadingComponent],
  templateUrl: './loading-screen.component.html',
  styleUrl: './loading-screen.component.scss',
})
export class LoadingScreenComponent {}
