import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  model,
  OnInit,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

export type DateYearPickerModel = {
  month?: number;
  year?: number;
};

@Component({
  selector: 'app-date-year-picker',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
  ],
  templateUrl: './date-year-picker.component.html',
  styleUrl: './date-year-picker.component.scss',
})
export class DateYearPickerComponent implements OnInit {
  readonly date = new FormControl();

  value = model<DateYearPickerModel>();

  ngOnInit(): void {
    setTimeout(() => {
      this.date.setValue(this.getMontAndYear());
    });
  }

  ngAfterViewChecked(): void {}

  setMonthAndYear(
    normalizedMonthAndYear: Date,
    datepicker: MatDatepicker<Date>
  ) {
    this.date.setValue(this.getMontAndYear(normalizedMonthAndYear));
    datepicker.close();
  }

  getMontAndYear(date?: Date): string {
    if (!date) date = new Date();

    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    this.value.set({ month, year });

    return `${month}/${year}`;
  }
}
