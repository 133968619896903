import { Component, inject, input, OnInit } from '@angular/core';
import { getShortName } from '../../models/colaborador.model';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-profile-picture',
  standalone: true,
  imports: [NgClass, MatTooltipModule, NgTemplateOutlet],
  templateUrl: './profile-picture.component.html',
  styleUrl: './profile-picture.component.scss',
})
export class ProfilePictureComponent implements OnInit {
  private apiService = inject(ApiService);

  user = input.required<{ id?: string, name?:string }>();

  size = input<'small' | 'normal' | 'big' | 'medium'>('normal');

  shortenName = input<boolean>(false);
  hideName = input<boolean>(false);

  picUrl: string = '';

  ngOnInit() {
    this.picUrl =
      this.apiService.profilePicEndpoint +
      '/storage/profile_picture/' +
      this.user().id;
  }

  getShortName = getShortName;
}
