import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-live-board',
  standalone: true,
  imports: [],
  templateUrl: './live-board.component.html',
  styleUrl: './live-board.component.scss',
})
export class LiveBoardComponent implements OnInit, OnDestroy {
  @ViewChild('messagesContainer', { static: false })
  messagesContainer!: ElementRef;
  currentIndex: number = 1;
  paragraphs!: HTMLParagraphElement[];
  intervalId: any; // To store the interval ID

  private renderer = inject(Renderer2)

  ngOnInit(): void {    
    this.intervalId = setInterval(() => {

      this.paragraphs = this.messagesContainer.nativeElement.querySelectorAll('p');

      // Remove 'slide-in' class from all paragraphs
      this.paragraphs.forEach((paragraph: HTMLParagraphElement) => {
        this.renderer.removeClass(paragraph, 'slide-in');
        this.renderer.addClass(paragraph, 'hidden');
      });

      // Add 'slide-in' class to the current paragraph
      const paragraph = this.paragraphs[this.currentIndex];
      this.renderer.addClass(paragraph, 'slide-in');
      this.renderer.removeClass(paragraph, 'hidden');

      // Update the index to move to the next paragraph
      this.currentIndex = (this.currentIndex + 1) % this.paragraphs.length;
    }, 10000);
  }

  ngOnDestroy(): void {
    // Clear the interval to avoid memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
