@if(paginatedService.notFound){
<app-not-found></app-not-found>
}
@else if(paginatedService.dataSource.data.length == 0){
<app-loading-screen></app-loading-screen>
}

<div class="card w-full relative" [ngClass]="{'hidden': paginatedService.dataSource.data.length == 0}">

    <div class="h-full w-full justify-center items-center absolute top-0 left-0 z-10 bg-black bg-opacity-5 rounded-default"
        [ngClass]="{'hidden': !colaboradorService.isLoading, 'flex': colaboradorService.isLoading}">
        <app-loading [shape]="'circle'"></app-loading>
    </div>

    <table mat-table [dataSource]="colaboradorService.dataSource" class="table !shadow-none">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                <span class="max-md:hidden">Nome</span>
                <span class="md:hidden">
                    <mat-icon class="mat-18">work</mat-icon>
                    Colaboradores
                </span>
            </th>
            <td mat-cell *matCellDef="let colaborador">
                <div class="flex flex-row gap-5 items-center !py-3">

                    <app-profile-picture [user]="colaborador"></app-profile-picture>

                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Email </th>
            <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">
                {{colaborador.email}}
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Cargo </th>
            <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">
                <div class="flex flex-row gap-2">
                    <chip color="blue">
                        @if(colaborador.email == 'pablogaudard@gmail.com'){
                        Desenvolvedor
                        }@else{
                        {{colaborador.role}}
                        }
                    </chip>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Unidade </th>
            <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">
                {{colaborador.unit}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Status </th>
            <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">
                @if(colaborador.active) {
                <chip color="green">
                    Ativo
                </chip>
                }
                @else {
                <chip color="red">
                    Inativo
                </chip>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let colaborador">

                @let hasCoordenadorPermission = authService.hasRole(authService.roles.Coordenador) && colaborador.roleId == authService.roles.Professor;
                @let isSelf = authService.currentUser.id == colaborador.id;

                <button mat-icon-button class="!overflow-hidden !rounded-full float-right"
                    [disabled]="!authService.hasRole(authService.roles.Adm) && !hasCoordenadorPermission && !isSelf"
                    [matMenuTriggerFor]="options">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #options="matMenu">
                    <button mat-menu-item (click)="edit(colaborador)">
                        <mat-icon>edit</mat-icon>
                        <span class="font-normal text-light">Editar</span>
                    </button>
                </mat-menu>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="!mobileQueryService.matches" [hidePageSize]="true">
    </mat-paginator>

</div>