import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatDividerModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent {
  daysOfTheWeek: string[] = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

  month = {
    weeks: [
      { days: [{}, {}, {}, {}, {}, {}, {}] },
      { days: [{}, {}, {}, {}, {}, {}, {}] },
      { days: [{}, {}, {}, {}, {}, {}, {}] },
      { days: [{}, {}, {}, {}, {}, {}, {}] },
    ],
  };
}
