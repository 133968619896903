import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MobileQueryService } from '../../services/mobile-query.service';
import { PessoaFormComponent } from '../pessoa-form/pessoa-form.component';
import { MatRadioModule } from '@angular/material/radio';
import { FinanceiroFormComponent } from '../financeiro-form/financeiro-form.component';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';

enum EResponsavelType {
  PROPRIO_ALUNO = 1,
  PESSOA_FISICA,
  PESSOA_JURIDICA,
}

@Component({
  selector: 'app-matricula-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogTitle,
    MatButtonModule,
    MatDialogClose,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    PessoaFormComponent,
    MatRadioModule,
    FinanceiroFormComponent,
  ],
  templateUrl: './matricula-dialog.component.html',
  styleUrl: './matricula-dialog.component.scss',
})
export class MatriculaDialogComponent implements AfterViewInit, OnDestroy {
  protected apiService = inject(ApiService);
  protected mobileQueryService = inject(MobileQueryService);
  private cd = inject(ChangeDetectorRef);

  @ViewChild('alunoForm', { static: false }) alunoForm!: PessoaFormComponent;
  @ViewChild('respForm', { static: false }) respForm!: PessoaFormComponent;
  @ViewChild('financeiroForm', { static: false }) financeiroForm?: FinanceiroFormComponent;

  readonly dialogRef = inject(MatDialogRef<MatriculaDialogComponent>);

  protected EResponsavelType = EResponsavelType;
  responsavelType?: EResponsavelType;

  private alunoFormSubscription?: Subscription;

  ngAfterViewInit(): void {
    this.alunoFormSubscription =
      this.alunoForm.personalInfoForm.valueChanges.subscribe((response) => {
        if (this.responsavelType == EResponsavelType.PROPRIO_ALUNO)
          this.respForm.personalInfoForm.patchValue(response);
      });

    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.alunoFormSubscription?.unsubscribe();
  }

  isStudentAdult(): boolean {
    if (!this.alunoForm) return false;

    const birthdate = this.alunoForm.personalInfoForm.get('birthdate')?.value;

    if (!birthdate) return false;

    const today = new Date();
    const age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();

    // If the birth month has not yet occurred this year, subtract one from age
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthdate.getDate())
    ) {
      return age >= 18;
    }

    return age > 18;
  }

  onStudentBirthdayChange() {
    if (this.isStudentAdult()) {
      this.updateResponsavelType(EResponsavelType.PROPRIO_ALUNO);
    } else {
      this.updateResponsavelType(EResponsavelType.PESSOA_FISICA);
    }
  }

  updateResponsavelType(responsavelType: EResponsavelType) {
    this.responsavelType = responsavelType;
    if (responsavelType == EResponsavelType.PROPRIO_ALUNO) {
      this.respForm.personalInfoForm.patchValue(
        this.alunoForm.personalInfoForm.value
      );
      this.respForm.personalInfoForm.disable();
    } else {
      this.respForm.personalInfoForm.reset();
      this.respForm.personalInfoForm.enable();
    }
  }

  finalizarMatricula() {
    const student = this.alunoForm.personalInfoForm.value;
    const responsavel = this.respForm.personalInfoForm.value;
    const responsavelType = this.responsavelType;

    const financeiro = this.financeiroForm?.formGroup.value;

    this.apiService
      .post('matricula/', {
        student,
        responsavel,
        responsavelType,
        financeiro
      })
      .subscribe();

    this.dialogRef.close();
  }
}
