import { HttpEventType } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
  selector: 'app-upload-video-dialog',
  standalone: true,
  imports: [
    MatDialogActions,
    MatButtonModule,
    MatDialogContent,
    MatDialogTitle,
    MatIconModule,
    MatProgressBarModule
  ],
  templateUrl: './upload-video-dialog.component.html',
  styleUrl: './upload-video-dialog.component.scss',
})
export class UploadVideoDialogComponent {
  selectedFile: File | null = null;
  uploadProgress?: number;
  uploadMessage: string = '';
  fileName?: string;

  apiService = inject(ApiService);

  readonly dialogRef = inject(MatDialogRef<UploadVideoDialogComponent>);  
  readonly data = inject<{videoType: VideoType}>(MAT_DIALOG_DATA);
  readonly videoTypes = VideoType;

  // Handle file input change event
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && file.type.startsWith('video/')) {
      this.selectedFile = file;


      this.fileName = file.name;
    } else {
      this.uploadMessage = 'Please select a valid video file.';
      this.selectedFile = null;
    }
  }

  // Upload the selected video file
  onUpload(): void {
    if (!this.selectedFile) {
      this.uploadMessage = 'No file selected!';
      return;
    }

    const formData = new FormData();
    formData.append('video', this.selectedFile);
    formData.append('videoType', this.data.videoType); // Add the video type to the form data

    this.uploadProgress = 0;
    this.apiService
      .post('storage/upload_video', formData, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            this.uploadProgress = Math.round(100 * (event.loaded / event.total));
          } else if (event.type === HttpEventType.Response) {
            this.uploadProgress = 100;
            this.uploadMessage = 'Upload concluído!'
          }
        },
        error: (error) => {
          this.uploadMessage = `Upload failed: ${error.message}`;
          this.uploadProgress = 0;
        },
      });
  }

  close(){
    this.dialogRef.close(this.uploadProgress == 100)
  }

}

export enum VideoType {
  REVISION = 'revision',
  NEW_WORK = 'new_work',
}
