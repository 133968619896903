import { Component, inject } from '@angular/core';
import { PlanejamentoComponent } from '../../views/planejamento/planejamento.component';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { planejamento } from '../../views/planejamento/planejamento.service';
import { ApiService } from '../../services/api.service';
import { DiaryService } from '../../services/diary.service';

@Component({
  selector: 'app-planejamento-dialog',
  standalone: true,
  imports: [
    PlanejamentoComponent,
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
  ],
  templateUrl: './planejamento-dialog.component.html',
  styleUrl: './planejamento-dialog.component.scss',
})
export class PlanejamentoDialogComponent {
  readonly data = inject<{
    edition: number;
    estagio: number;
    classroomId: string;
  }>(MAT_DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef<PlanejamentoDialogComponent>);
  protected diaryService = inject(DiaryService);

  private apiService = inject(ApiService);

  protected planejamentoId?: number;

  onPlanejamentoSelected(planejamento: planejamento) {
    this.planejamentoId = planejamento.id;
  }

  ajustar() {
    this.dialogRef.close();

    this.apiService
      .post('classrooms/ajustar_diario', {
        classroom_id: this.data.classroomId,
        planejamento_id: this.planejamentoId,
      })
      .subscribe(() => {
        this.diaryService.load(this.data.classroomId);
      });
  }
}
