import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExamsService {

  public endpoints = {
    // backend: environment.apiUrl,
    frontend: 'https://exam.uskinglesintensivo.com.br/',
  };

  private apiUrl = 'https://uskinglesintensivo.com.br/provas-online/api/v1/';
  private http = inject(HttpClient);
  public isLoading = false;

  constructor() { }

  get(endpoint: string, params?:any): Observable<any> {
    this.isLoading = true;

    return this.http
      .get<any>(`${this.apiUrl}/${endpoint}`, {params})
      .pipe(finalize(() => {
        this.isLoading = false
      }));
  }
}
