import { Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { ClassroomAssessmentStatus } from '../teacher-dashboard/teacher-dashboard.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-assessment-dialog',
  standalone: true,
  imports: [
    MatTableModule,
    MatIconModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogClose,
    MatButtonModule,
    RouterModule
  ],
  templateUrl: './assessment-dialog.component.html',
  styleUrl: './assessment-dialog.component.scss',
})
export class AssessmentDialogComponent  {

  readonly dataSource = inject<ClassroomAssessmentStatus[]>(MAT_DIALOG_DATA);

  displayedColumns: string[] = ['turma', 'status'];

  
}
