export interface IColaborador {
  id: string;
  name: string;
  email: string;
  cpf: string;
  username: string;
  role: string;
  roleId: number;
  unit: string;
  unitId: string;
  gender: string;
  avatarId: number;
  active: boolean;
}

export function getShortName(name: any): string {

  if (typeof name != 'string')
    return '--';

  // Replace multiple spaces with a single space
  const cleanedName = name.replace(/\s+/g, ' ').trim();

  const words = cleanedName.split(' ');

  if (words.length < 2) {
    return words[0]; // Return just the first word if there's only one
  }

  const secondWord = words[1].toLowerCase(); // Convert to lowercase to avoid case sensitivity

  // Check if the second word is "de", "da", or "do"
  if (['de', 'da', 'do', 'das', 'dos'].includes(secondWord)) {
    return words.slice(0, 3).join(' '); // Return the first three words
  }

  return words.slice(0, 2).join(' '); // Return the first two words
}
