<div class=" flex flex-row h-full bg-black">

    <div class="relative max-md:hidden" style="width: calc(100% - 450px);">

        <div class="bg-cover bg-center opacity-20 w-full h-full"
            [ngStyle]="{'background-image': 'url(\'assets/' + bgImg + '.jpg\')'}">

        </div>
        <div class="w-full h-full absolute top-0 left-0 text-white p-5 flex justify-center items-center">
            <div class="flex flex-col items-center" style="transform: scale(1.4);">
                <app-logomark theme="light"></app-logomark>
                <p style="font-size: 0.94rem; margin-top: 0.5rem;">
                    Portal Pedagógico
                </p>
            </div>
        </div>

    </div>
    <form class="flex flex-col w-full md:w-[450px] justify-center p-5 md:p-10" [formGroup]="loginForm"
        style="background: linear-gradient(175deg, var(--bg-color) 10%, white 45%);">

        <div class="md:hidden">
            <app-logomark theme="dark"></app-logomark>
        </div>

        <div [ngClass]="{'hidden': !networkStatusService.isOnline}"  class="basis-full flex flex-col justify-center">

            <div class="flex flex-col gap-3 mb-5">
                <h4>Bem-vindo 👋🏻</h4>
                <p class="text-light">Faça login para continuar</p>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Usuário</mat-label>
                <input matInput formControlName="username">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Senha</mat-label>
                <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" type="button"
                    [attr.aria-label]="'Toggle password visibility'">
                    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
            </mat-form-field>

            <!-- <mat-checkbox>Manter-me conectado</mat-checkbox> -->

            <button mat-flat-button (click)="login()" [disabled]="loginForm.invalid">
                @if(apiService.isLoading){
                <app-loading class="invert"></app-loading>
                }
                @else {
                <span>Entrar</span>
                }
            </button>

        </div>

        <div [ngClass]="{'hidden': networkStatusService.isOnline}" class="basis-full flex flex-col items-center justify-center">
            <app-not-found message="Oops, você está offline..."></app-not-found>
        </div>

        @if(showInstallButton){
        <button mat-button (click)="installPWA()">
            <mat-icon class="mat-18">download</mat-icon>
            Instalar app
        </button>
        }

    </form>

</div>