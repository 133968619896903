<div [ngClass]="{'card': editable()}">

    <div class="flex flex-row py-5 border-b gap-5" [ngClass]="{'px-5': editable()}">

        <mat-form-field appearance="outline" class="no-padding w-full">
            <mat-label>Estágio</mat-label>
            <mat-select [(value)]="estagio" (valueChange)="getPlanejamento()">
                @for(estagio of estagios; track estagio){
                <mat-option [value]="estagio.id">{{estagio.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="no-padding w-full">
            <mat-label>Edition</mat-label>
            <mat-select [(value)]="edition" (valueChange)="getPlanejamento()">
                <mat-option [value]="2">2nd</mat-option>
                <mat-option [value]="3">3rd</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <table #table mat-table [dataSource]="planejamentoService.dataSource" class="!shadow-none md:text-center" [ngClass]="{'table': editable()}">

        <ng-container [matColumnDef]="'index'">
            <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap"> Nº Aula </th>
            <td mat-cell *matCellDef="let element; let i = index">
                
                {{ i + 1 }}
                
                </td>
        </ng-container>

        @for (column of displayedColumns; track $index) {
        @if (!['index', 'actions'].includes(column)) {

        @let alignTextAtCenter = ['dictation', 'reading'].includes(column) && mobileQueryService.matches;

        <ng-container [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef class="capitalize" [ngClass]="{'!text-center': alignTextAtCenter}">
                @if(mobileQueryService.matches && column == 'dictation'){
                <mat-icon>edit</mat-icon>
                }
                @else if(mobileQueryService.matches && column == 'reading'){
                <mat-icon>menu_book</mat-icon>
                }
                @else if(mobileQueryService.matches && column == 'materia'){
                Parágrafo | Matéria
                }@else {
                {{column}}
                }
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'!text-center': alignTextAtCenter}">
                @if(element.editMode){
                <mat-form-field appearance="outline" class="no-padding py-2 w-full">
                    <input matInput [type]="column == 'materia'? 'text': 'number'" [(ngModel)]="element[column]"
                        class="text-center">
                </mat-form-field>
                } @else {
                @if(mobileQueryService.matches && column == 'materia'){
                <span class="font-medium">
                    {{element.paragrafo}}
                </span>
                @if(element.paragrafo){
                <span>-</span>
                }
                {{element.materia}}
                }
                @else {
                {{ element[column]||'--'}}
                }
                }
            </td>
        </ng-container>
        }
        }

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="text-center">

                @if(!element.editMode) {
                <button mat-icon-button class="!overflow-hidden !rounded-full float-right"
                    [matMenuTriggerFor]="options">
                    <mat-icon>more_vert</mat-icon>
                </button>
                }
                @else {
                @if (!element.materia && !element.id){
                <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="removeFromArray(i)">
                    <mat-icon>close</mat-icon>
                </button>
                }
                @else{
                <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="upsert(element)"
                    [disabled]="!element.materia">
                    <mat-icon>save</mat-icon>
                </button>
                }
                }
                <mat-menu #options="matMenu">
                    <button mat-menu-item (click)="element.editMode = true">
                        <mat-icon>edit</mat-icon>
                        <span class="font-normal text-light">Editar</span>
                    </button>
                    <button mat-menu-item (click)="insertItem(element, 'above')">
                        <mat-icon>arrow_upward</mat-icon>
                        <span class="font-normal text-light">Inserir acima</span>
                    </button>
                    <button mat-menu-item (click)="insertItem(element, 'below')">
                        <mat-icon>arrow_downward</mat-icon>
                        <span class="font-normal text-light">Inserir abaixo</span>
                    </button>
                    <button mat-menu-item (click)="deleteItem(element, i)">
                        <mat-icon>delete</mat-icon>
                        <span class="font-normal text-light">Remover</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Header and Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns" 
            [ngClass]="{
                'cursor-pointer hover:bg-gray-100':!editable(), 
                '!bg-blue-500 !text-white': !!row.id && selectedRow?.id == row.id,
                '!bg-gray-200': (i + 1) % 5 == 0
            }" 
            (click)="selectRow(row)"></tr>
    </table>

    <!-- Optional paginator and sorting (if needed) -->
    <!-- <mat-paginator pageSize="10" [pageSizeOptions]="[10,25, 50, 100, 500]" showFirstLastButtons></mat-paginator> -->

</div>