
<div class="flex flex-row justify-start">

    <mat-form-field appearance="outline" (click)="picker.open()">
        <mat-label>Mês e Ano</mat-label>
        <input matInput [formControl]="date" [readonly]="true" >
        <input matInput [matDatepicker]="picker" class="!hidden">
        <button matSuffix mat-icon-button class="mr-2">
            <mat-icon>today</mat-icon>
        </button>
        <mat-datepicker #picker startView="year" (monthSelected)="setMonthAndYear($event, picker)"></mat-datepicker>
    </mat-form-field>
    
</div>