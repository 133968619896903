
<div class="flex flex-row gap-5 mb-5">
    <button mat-flat-button class="text-nowrap">
        <mat-icon>add</mat-icon>
        Novo vento
    </button>

    <div class="flex flex-row items-center">

        <button mat-icon-button>
            <mat-icon class="mat-18">chevron_left</mat-icon>
        </button>


        <button mat-icon-button>
            <mat-icon class="mat-18">chevron_right</mat-icon>
        </button>

        <p class="pl-2">November 2024</p>
    </div>

</div>



<div class="card flex flex-row gap-5">



    <div class="calendar">


        <div class="days-of-the-week">
            @for (dayName of daysOfTheWeek; track dayName){
            <div class="day-name">
                {{dayName}}
            </div>
            }
        </div>
        @for (week of month.weeks; track week){
        <div class="week">
            @for (day of week.days; track day){
            <div class="day">

            </div>
            }

        </div>
        }
    </div>


</div>