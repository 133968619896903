function toCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter: string) => letter.toUpperCase());
}

/**
 * Recursively binds properties of an object from underscore case to camel case.
 * Converts all keys in the object to camelCase and retains the original values.
 * If a property value is an object, it recursively converts its keys as well.
 *
 * @param {Record<string, any>} obj - The object with underscore_case keys.
 * @returns {Record<string, any>} A new object with keys in camelCase.
 */
export function bindObjectPropsToCamelCase(obj: Record<string, any>): any {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelCaseKey = toCamelCase(key);
      // Recursively handle nested objects if needed

      const isObject = typeof obj[key] === 'object' && !Array.isArray(obj[key]);

      if (obj[key] === null || !isObject) {
        newObj[camelCaseKey] = obj[key];
      } else {
        newObj[camelCaseKey] = bindObjectPropsToCamelCase(obj[key]);
      }
    }
  }

  return newObj;
}
