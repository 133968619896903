import { inject, Injectable } from '@angular/core';
import { SCHOOL_UNITS } from '../components/turma-dialog/turma-dialog.component';
import { AuthService } from './auth.service';


type option = Record<'id' | 'name', string | number>;

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  private authService = inject(AuthService)

  private _unidades: option[] = SCHOOL_UNITS;
  
  unidades(): option[]{
    const canSeeAll = this.authService.hasRole([this.authService.roles.Adm, this.authService.roles.Comercial]);
    
    return this._unidades.filter((school) => {
      if (canSeeAll) return true;
      else return school.id == this.authService.currentUser.schoolId;
    });
  }

}
