import { Injectable } from '@angular/core';
import { BasePaginatedService } from '../../abstracts/base-paginated.service';

@Injectable({
  providedIn: 'root'
})
export class AlunosService extends BasePaginatedService<any> {

  override resourceUrl: string = 'students/';

}