@if(paginatedService.notFound){
<app-not-found></app-not-found>
}
@else if(paginatedService.dataSource.data.length == 0){
<app-loading-screen></app-loading-screen>
}

<div class="card relative" [ngClass]="{'hidden': paginatedService.dataSource.data.length == 0}">

    <div class="h-full w-full justify-center items-center absolute top-0 left-0 z-10 bg-black bg-opacity-5 rounded-default"
        [ngClass]="{'hidden': !paginatedService.isLoading, 'flex': paginatedService.isLoading}">
        <app-loading [shape]="'circle'"></app-loading>
    </div>

    <table mat-table [dataSource]="paginatedService.dataSource" class="table !shadow-none">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                <span>Aluno</span>
            </th>
            <td mat-cell *matCellDef="let student" class="!py-3">
                <app-profile-picture [user]="student" [shortenName]="mobileQueryService.matches">
                    <span class="text-light text-nowrap md:hidden">{{student.classroom_name}}</span>
                </app-profile-picture>
            </td>
        </ng-container>

        <ng-container matColumnDef="school">
            <th mat-header-cell *matHeaderCellDef>
                <span>Unidade</span>
            </th>
            <td mat-cell *matCellDef="let student">
                <span>{{student.school_name}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="classroom">
            <th mat-header-cell *matHeaderCellDef>
                <span>Turma</span>
            </th>
            <td mat-cell *matCellDef="let student" class="!py-4">
                <p>
                    <span>{{student.classroom_name}}</span>
                </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                <span>Status</span>
            </th>
            <td mat-cell *matCellDef="let student" class="!py-4">

                @if(student.active) {
                    <chip color="green">Ativo</chip>
                }
                @else {
                    <chip color="red">Inativo</chip>
                }
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="mobileQueryService.matches ? mobileColumns : desktopColumns"></tr>
        <tr mat-row *matRowDef="let student; columns: mobileQueryService.matches ? mobileColumns : desktopColumns;" [routerLink]="['/aluno', student.id]">
        </tr>
    </table>

    <mat-paginator [showFirstLastButtons]="!mobileQueryService.matches" [hidePageSize]="true">
    </mat-paginator>

</div>