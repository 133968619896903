import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { tap } from 'rxjs';
import {
  TaskComment,
  TaskComments,
} from '../../components/tarefa-preview/tarefa-preview.component';

export type Tarefa = {
  id: number;
  title: string;
  description: string;
  status_id: number;
  assignees_ids: string[];
  assignees: { id: string; gender: string; avatarId: number }[];
  comments_count: number;
  tags: string[];
};

@Injectable({
  providedIn: 'root',
})
export class TarefasService {
  private apiService = inject(ApiService);
  public tasks: Tarefa[] = [];

  public isLoading = signal<boolean>(false);

  upsert(tarefa: Tarefa, reportLoading: boolean = true) {
    if (reportLoading) this.isLoading.set(true);

    return this.apiService.post('tasks/', tarefa).pipe(
      tap(() => {
        if (reportLoading) this.isLoading.set(false);
      })
    );
  }

  getAll(tags?: string[]) {

    this.isLoading.set(true);

    this.apiService
      .getByFilters<Tarefa[]>('tasks/',{
        tags
      })
      .pipe(
        tap(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe((tasks) => (this.tasks = tasks));
  }

  upsertComment(comment: TaskComment) {
    return this.apiService.post('tasks/comments', comment);
  }

  getCommentsByTaskId(task_id: number) {
    return this.apiService.getByFilters<TaskComments>('tasks/comments', {
      task_id,
    });
  }

  getTags() {
    return this.apiService.get<{ id: number; name: string }[]>('tasks/tags');
  }
}
