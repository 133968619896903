import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profile-picture-upload',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
  ],
  templateUrl: './profile-picture-upload.component.html',
  styleUrl: './profile-picture-upload.component.scss',
})
export class ProfilePictureUploadComponent implements AfterViewInit {
  selectedFile: File | null = null;
  previewUrl: string | ArrayBuffer | null = null;
  uploadUrl = '/storage/upload_profile_picture'; // Replace with your actual backend URL

  @ViewChild('fileInput') fileInput!: ElementRef; // Reference to the input element

  protected apiService = inject(ApiService);

  readonly dialogRef = inject(MatDialogRef<ProfilePictureUploadComponent>);
  private snackBar = inject(MatSnackBar);

  ngAfterViewInit(): void {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      this.selectedFile = fileInput.files[0];

      // Display image preview
      const reader = new FileReader();
      reader.onload = (e) => (this.previewUrl = reader.result);
      reader.readAsDataURL(this.selectedFile);
    }
  }

  onUpload(): void {
    if (!this.selectedFile) {
      alert('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('profile_picture', this.selectedFile);

    this.apiService
      .post(this.uploadUrl, formData)
      .subscribe((response) => {
        if (response) {
          this.dialogRef.close();
          this.snackBar.open('Foto de perfil atualizada com sucesso!', 'ok');
        }
      });
  }
}