import { inject, Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvaliacoesService {
  private apiService = inject(ApiService);

  public dataSource = new MatTableDataSource<any>([]);

  getAll(): Observable<avaliacao[]> {
    if (this.dataSource.data.length == 0)
      return this.apiService.get<avaliacao[]>('assessments/teachers').pipe(
        tap((response) => {
          this.dataSource.data = response;
        })
      );

    return of(this.dataSource.data);
  }
}

type avaliacao = {
  avatarId: number;
  email: string;
  gender: 'm' | 'f';
  id: string;
  name: string;
  new_work_uploaded: boolean;
  revision_uploaded: boolean;
  score: number | null;
  username: string;
};
