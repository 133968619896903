import { Component, inject, OnInit, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { getShortName } from '../../models/colaborador.model';
import { Tarefa, TarefasService } from '../../views/tarefas/tarefas.service';
import { ColaboradorService } from '../../services/colaborador.service';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { LiveAnnouncer } from '@angular/cdk/a11y';

type option = Record<'id' | 'name', string | number>;

@Component({
  selector: 'app-tarefa-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    MatDialogTitle,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    FormsModule,
  ],
  templateUrl: './tarefa-dialog.component.html',
  styleUrl: './tarefa-dialog.component.scss',
})
export class TarefaDialogComponent implements OnInit {
  tarefaForm: FormGroup;

  protected tarefasService = inject(TarefasService);
  readonly dialogRef = inject(MatDialogRef<TarefaDialogComponent>);
  readonly data?: Tarefa = inject(MAT_DIALOG_DATA);
  protected colaboradorService = inject(ColaboradorService);

  people: option[] = [];

  getShortName = getShortName;

  constructor(private fb: FormBuilder) {
    this.tarefaForm = this.fb.group<Partial<Record<keyof Tarefa, unknown>>>({
      id: [undefined],
      title: ['', Validators.required],
      description: [''],
      status_id: ['', Validators.required],
      assignees_ids: [[], Validators.required],
      tags: [[]],
    });
  }

  ngOnInit(): void {
    this.colaboradorService.getSummary().subscribe((professores) => {
      this.people = professores.items;
      if (this.data) {
        this.tarefaForm.patchValue(this.data);

        this.reactiveKeywords.set(this.tarefaForm.get('tags')?.value)
      }
    });
  }

  onSubmit() {
    if (this.tarefaForm.invalid) return;

    this.tarefasService.upsert(this.tarefaForm.value).subscribe(() => {
      this.dialogRef.close();
      this.tarefasService.getAll();
    });
  }

  readonly reactiveKeywords = signal<string[]>([]);

  announcer = inject(LiveAnnouncer);

  removeReactiveKeyword(keyword: string) {
    this.reactiveKeywords.update((keywords) => {
      const index = keywords.indexOf(keyword);
      if (index < 0) {
        return keywords;
      }

      keywords.splice(index, 1);
      this.announcer.announce(`removed ${keyword} from reactive form`);
      return [...keywords];
    });
  }

  addReactiveKeyword(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.reactiveKeywords.update((keywords) => [...keywords, value]);
      this.announcer.announce(`added ${value} to reactive form`);
    }

    // Clear the input value
    event.chipInput!.clear();
  }
}
