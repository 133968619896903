import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TarefaDialogComponent } from '../../components/tarefa-dialog/tarefa-dialog.component';
import { Tarefa, TarefasService } from './tarefas.service';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { NgClass } from '@angular/common';
import { TarefaPreviewComponent } from '../../components/tarefa-preview/tarefa-preview.component';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { ChipComponent } from '../../components/chip/chip.component';
import { getColorFromString } from '../../utils/random-int.util';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { arraysAreEqual } from '../../utils/compare-arrays.util';
import { AuthService } from '../../services/auth.service';

export const TarefaStatus: { id: number; name: string }[] = [
  { id: 1, name: 'A Fazer' },
  { id: 2, name: 'Em Progresso' },
  { id: 3, name: 'Concluído' },
];

@Component({
  selector: 'app-tarefas',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    LoadingScreenComponent,
    NgClass,
    ProfilePictureComponent,
    CdkDropList,
    CdkDrag,
    ChipComponent,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    CdkDragHandle
  ],
  templateUrl: './tarefas.component.html',
  styleUrl: './tarefas.component.scss',
})
export class TarefasComponent implements OnInit {
  protected statuses = TarefaStatus;
  readonly dialog = inject(MatDialog);

  protected tarefasService = inject(TarefasService);
  protected authService = inject(AuthService);

  getColorFromString = getColorFromString;

  tags: FormControl<string[] | undefined> = new FormControl();

  toppingList: string[] = [];

  private previousFilter: string[] = [];

  onFilter() {
    if (!this.tags.value) return;

    if (arraysAreEqual(this.previousFilter, this.tags.value)) return;

    this.tarefasService.getAll(this.tags.value);
    this.previousFilter = this.tags.value;
  }

  openTarefaDialog(status_id: number) {
    this.dialog.open(TarefaDialogComponent, {
      width: '450px',
      data: {
        status_id,
      },
    });
  }

  ngOnInit(): void {
    this.tarefasService.getAll();

    this.tarefasService.getTags().subscribe((tags) => {
      this.toppingList = tags.map((tag) => {
        return tag.name;
      });
    });
  }

  openTarefaPreviewDialog(task: Tarefa) {
    this.dialog.open(TarefaPreviewComponent, {
      width: '600px',
      maxWidth: '600px',
      data: task,
      autoFocus: false,
    });
  }

  getListId(statusId: number): string {
    return `list-${statusId}`;
  }

  getListConnectedTo(statusId: number) {
    return this.statuses
      .filter((s) => s.id != statusId)
      .map((s) => this.getListId(s.id));
  }

  drop(event: any) {
    if (event.previousContainer !== event.container) {
      const taskId = Number(event.item.element.nativeElement.id.split('-')[1]);
      const targetStatusId = Number(
        event.container.element.nativeElement.id.split('-')[1]
      );

      this.tarefasService.tasks = this.tarefasService.tasks.map((task) => {
        if (task.id == taskId && task.status_id != targetStatusId) {
          task.status_id = targetStatusId;
          this.tarefasService.upsert(task, false).subscribe();
        }

        return task;
      });
    }
  }

  getListData(statusId: number) {
    return this.tarefasService.tasks.filter((t) => t.status_id == statusId);
  }
}
