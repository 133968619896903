import { Injectable } from '@angular/core';
import { IColaborador } from '../models/colaborador.model';
import { finalize, Observable, of, tap } from 'rxjs';
import { BasePaginatedService } from '../abstracts/base-paginated.service';
import { IPaginatedResult } from '../models/paginatedResult.model';

@Injectable({
  providedIn: 'root',
})
export class ColaboradorService extends BasePaginatedService<IColaborador> {
  override resourceUrl: string = 'users/';

  put(colaborador: IColaborador) {
    this._isLoading = true;
    return this.apiService.put(`users/`, colaborador).pipe(
      finalize(() => {
        this._isLoading = false;
      })
    );
  }

  private summary: IColaborador[] = [];

  getSummary(): Observable<IPaginatedResult<IColaborador>> {
    if (this.summary.length)
      return of({ items: this.summary, total: this.summary.length });

    return this.apiService
      .getByFilters<IPaginatedResult<IColaborador>>('users/', {
        summarized: true,
        pageSize: 100,
      })
      .pipe(
        tap((response) => {
          this.summary = response.items;
        })
      );
  }
}
