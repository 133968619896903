<h2 style="margin-left: -0.5rem;" mat-dialog-title>
    Ajustar Planejamento
</h2>
<mat-dialog-content>
    <p class="pb-4">Selecione o plano de aula correspondente ao dia de hoje para ajustar o planejamento.</p>
</mat-dialog-content>

<mat-dialog-content class="!pt-0">
    <app-planejamento [editable]="false" [edition]="data.edition" [estagio]="data.estagio"
        (planejamentoSelected)="onPlanejamentoSelected($event)"></app-planejamento>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
    <button mat-flat-button cdkFocusInitial (click)="ajustar()" [disabled]="!planejamentoId">Ajustar</button>
</mat-dialog-actions>