<h2 mat-dialog-title style="margin-left: -0.5rem;">
    Confirmação
</h2>

<mat-dialog-content>

    <div class="flex flex-col">

        <p class="!mb-4">
            Você está prestes a remover {{data.student.name}} da turma.
            Por favor, selecione ou escreva o motivo para a remoção:
        </p>

        <mat-form-field class="example-full-width">
            <mat-label>Motivo da remoção</mat-label>
            <input type="text" matInput [formControl]="reasonControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                @for (option of filteredOptions | async; track option) {
                <mat-option [value]="option">{{option}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>

        <p>
            Deseja continuar com a remoção?
        </p>

    </div>

</mat-dialog-content>

<mat-dialog-actions>
    @if(!apiService.isLoading){
    <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
    }
    <button mat-flat-button color="warn" (click)="onRemoval()"
        [disabled]="reasonControl.invalid || apiService.isLoading">Remover da turma</button>
</mat-dialog-actions>