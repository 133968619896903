<h2 mat-dialog-title style="margin-left: -0.5rem;">
    Adicionar Aluno
</h2>

<mat-dialog-content>


    <div class="flex flex-col">
        <mat-form-field>
            <mat-label>Busca  por nome</mat-label>
            <input type="text" aria-label="Nome do aluno" matInput [formControl]="alunoControl" [matAutocomplete]="auto"
                (keyup)="onSearch()">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                @for (option of options; track option) {
                <mat-option [value]="option">{{option.name}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
    <button mat-flat-button (click)="addStudent()" [disabled]="!alunoControl.value?.id || isLoading">Adicionar</button>
</mat-dialog-actions>