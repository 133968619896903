import { NgClass } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { LogomarkComponent } from '../logomark/logomark.component';
import { MatListModule } from '@angular/material/list';
import { IMenuItem } from '../../models/menu-item.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ColaboradorDialogComponent } from '../colaborador-dialog/colaborador-dialog.component';
import { ECargo } from '../../enums/e-cargo.enum';
import { TurmaDialogComponent } from '../turma-dialog/turma-dialog.component';
import { NotificationService } from '../../services/notification.service';
import { MobileQueryService } from '../../services/mobile-query.service';
import { ApiService } from '../../services/api.service';
import { MatriculaDialogComponent } from '../matricula-dialog/matricula-dialog.component';
import { TarefaDialogComponent } from '../tarefa-dialog/tarefa-dialog.component';

@Component({
  selector: 'app-base-layout',
  standalone: true,
  imports: [
    MatSidenavModule,
    NgClass,
    RouterModule,
    ToolbarComponent,
    LogomarkComponent,
    MatListModule,
    MatIconModule,
  ],
  templateUrl: './base-layout.component.html',
  styleUrl: './base-layout.component.scss',
})
export class BaseLayoutComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  private authService = inject(AuthService);
  private mobileQueryService = inject(MobileQueryService);
  private apiService = inject(ApiService);

  fillerNav: IMenuItem[] = [
    {
      label: 'Turmas',
      path: '/',
      match: ['/turma'],
      icon: 'home',
      children: [
        {
          label: 'Nova turma',
          icon: 'add',
          action: () => {
            this.dialog.open(TurmaDialogComponent);
          },
          hide: () => {
            return !this.authService.hasRole([ECargo.Adm, ECargo.Coordenador, ECargo.Comercial]);
          },
        },
      ],
    },
    {
      label: 'Planejamento',
      path: '/planejamento',
      match: ['/planejamento'],
      icon: 'event_note',
      hide: () => {
        return this.authService.hasRole([ECargo.Comercial]);
      },
    },
    {
      label: 'Alunos',
      path: '/alunos',
      match: ['/aluno'],
      icon: 'group',
      hide: () => {
        return !this.authService.hasRole([ECargo.Adm, ECargo.Coordenador, ECargo.Comercial]);
      },
      children: [
        {
          label: 'Matrícula',
          icon: 'add',
          action: () => {
            const width = this.mobileQuery.matches ? 'auto' : '1000px';

            this.dialog.open(MatriculaDialogComponent, {
              width: width,
              maxWidth: width,
              disableClose: true,
            });
          },
        },
      ],
    },
    {
      label: 'Provas',
      path: '/provas',
      icon: 'description',
    },
    {
      label: 'Mapa de Risco',
      path: '/mapa-de-risco',
      icon: 'query_stats',
      hide: () => {
        return !this.authService.hasRole([ECargo.Adm, ECargo.Coordenador]);
      },
    },
    {
      label: 'Feedback',
      path: '/avaliacoes',
      icon: 'hotel_class',
      hide: () => {
        return !this.authService.hasRole([ECargo.Adm, ECargo.Coordenador]);
      },
    },
    {
      label: 'Tarefas',
      path: '/tarefas',
      icon: 'pending_actions',
      hide: () => {
        return !this.authService.hasRole([ECargo.Adm, ECargo.Coordenador, ECargo.Comercial]);
      },
      children: [
        {
          label: 'Novo',
          icon: 'add',
          action: () => {
            this.dialog.open(TarefaDialogComponent, {
              width: '450px',
            });
          },
        },
      ],
    },
    {
      label: 'Colaboradores',
      path: '/colaboradores',
      icon: 'work',
      hide: () => {
        return !this.authService.hasRole([ECargo.Adm, ECargo.Coordenador]);
      },
      children: [
        {
          label: 'Novo',
          icon: 'add',
          action: () => {
            this.dialog.open(ColaboradorDialogComponent);
          },
        },
      ],
    },
    {
      label: 'Comercial',
      icon: 'support_agent',
      action: () => {
        window.open('https://usk.htzap.com.br/login', '_blank');
      },
      hide: () => {
        return !this.authService.hasRole([ECargo.Adm, ECargo.Coordenador, ECargo.Comercial]);
      },
    },
    {
      label: 'Sair',
      icon: 'logout',
      action: () => {
        this.authService.logout();
      },
    },
  ];

  readonly dialog = inject(MatDialog);

  private _mobileQueryListener: () => void;

  currentUrl: string = '';

  @ViewChild('snav') snav!: MatSidenav;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.mobileQueryService.mediaQuery = this.mobileQuery;

    this.router.events.subscribe((url: any) => {
      this.currentUrl = this.router.url;
    });
  }

  isActive(nav: IMenuItem): boolean {
    // alert(this.currentUrl)
    return (
      this.currentUrl === nav.path ||
      (nav.match !== undefined && nav.match.indexOf(this.currentUrl) > -1) ||
      (nav.match !== undefined && this.currentUrl.includes(nav.match[0]))
    );
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onMenuClick(nav: IMenuItem) {
    if (nav.action) nav.action();

    if (this.mobileQuery.matches) this.snav.close();
  }
}
