<div class="h-full flex flex-col">

    <app-toolbar [snav]="snav"></app-toolbar>

    <mat-sidenav-container class="basis-full">

        <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" class="!rounded-none max-md:!shadow-xl" 
            style="width: var(--sidenav-width);">

            <div class="p-5 pb-0 md:hidden">
                <app-logomark [theme]="'dark'" [routerLink]="['/']" (click)="snav.close()"></app-logomark>
            </div>

            <mat-nav-list>
                @for (nav of fillerNav; track nav; let index = $index) {

                @if (nav.label == 'Sair'){
                <hr class="mt-2">
                }
        
                @if (nav.hide == undefined || !nav.hide()) {
                <a mat-list-item [routerLink]="nav.path" class="mt-2 menu-item" 
                    (click)="onMenuClick(nav)"
                    [ngClass]="{'active': isActive(nav)}">
                    <mat-icon class="mr-3 align-middle text-light" [ngClass]="{'!text-red-500':nav.icon == 'logout'}">
                        {{nav.icon}}
                    </mat-icon>
                    <span class="text-light" [ngClass]="{'!text-red-500':nav.icon == 'logout'}">
                        {{nav.label}}
                    </span>
                </a>

                @if(nav.children && isActive(nav)){
                @for (child of nav.children; track child;){
                @if (child.hide == undefined || !child.hide()) {
                    <a mat-list-item class="mt-2 menu-item" (click)="child?.action()">
                        <mat-icon class="mr-3 align-middle text-light">
                            {{child.icon}}
                        </mat-icon>
    
                        {{child.label}}
                    </a>
                }
                }
                }
                }
                }
            </mat-nav-list>

        </mat-sidenav>

        <mat-sidenav-content class="!flex !flex-col">
            <section class="p-5 basis-full">
                <router-outlet></router-outlet>
            </section>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>