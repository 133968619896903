<div class="flex flex-col gap-4 py-4">

  <a class="flex flex-row gap-4" [routerLink]="['aluno', data.student.id]" (click)="_bottomSheetRef.dismiss()">

    <app-profile-picture [user]="{id: data.student.id}" [hideName]="true" size="big"></app-profile-picture>

    <div>
      <p class="!mb-0 font-medium w-full overflow-hidden whitespace-nowrap text-ellipsis truncate">
        {{data.student.name}}
      </p>
      <p class="!mb-0 text-light">{{data.student.age}} anos</p>
    </div>
  </a>


  <button mat-raised-button (click)="openOcorrenciaModal()">
    <mat-icon class="mat-18">flag</mat-icon>
    Abrir ocorrência
  </button>

  <button mat-flat-button color="warn" (click)="onRemoval()" [disabled]="!canRemove()">
    <mat-icon class="mat-18">person_remove</mat-icon>
    Remover da turma
  </button>
</div>