import { Component, inject, model, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { Tarefa, TarefasService } from '../../views/tarefas/tarefas.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TarefaDialogComponent } from '../tarefa-dialog/tarefa-dialog.component';
import { TarefaStatus } from '../../views/tarefas/tarefas.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { NgClass } from '@angular/common';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { FormsModule } from '@angular/forms';
import { getShortName } from '../../models/colaborador.model';
import { calcularTempoPassado } from '../../utils/tempo-passado.util';

export type TaskComment = {
  id?: number;
  task_id: number;
  content: string;
};

export type TaskComments = {
  content: string;
  created_at: string;
  id: number;

  user: {
    id: string;
    avatarId: number;
    name: string;
    gender: string;
  };
}[];

@Component({
  selector: 'app-tarefa-preview',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogTitle,
    MatDialogClose,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    NgClass,
    ProfilePictureComponent,
    FormsModule,
  ],
  templateUrl: './tarefa-preview.component.html',
  styleUrl: './tarefa-preview.component.scss',
})
export class TarefaPreviewComponent implements OnInit {
  readonly data: Tarefa = inject(MAT_DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef<TarefaPreviewComponent>);
  readonly dialog = inject(MatDialog);

  protected status = TarefaStatus.find((t) => t.id == this.data.status_id)
    ?.name;

  private tarefasService = inject(TarefasService);

  commentText = model<string>();

  protected taskComments: TaskComments = [];

  getShortName = getShortName;

  calcularTempoPassado = calcularTempoPassado;

  ngOnInit(): void {
    this.getComments();
  }

  getComments() {
    this.tarefasService
      .getCommentsByTaskId(this.data.id)
      .subscribe((comments) => {
        this.taskComments = comments;
        this.data.comments_count = comments.length;
      });
  }

  openUpdateDialog(task: Tarefa) {
    this.dialogRef.close();

    this.dialog.open(TarefaDialogComponent, {
      width: '450px',
      data: task,
    });
  }

  upsertComment() {
    const content = this.commentText();

    if (!content) return;

    const taskComment: TaskComment = {
      content,
      task_id: this.data.id,
    };

    this.commentText.set('');
    this.tarefasService.upsertComment(taskComment).subscribe(() => {
      this.getComments();
    });
  }
}
