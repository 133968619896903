import { Component, inject, model, OnInit } from '@angular/core';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SchoolService } from '../../services/school.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from '../../services/api.service';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { TimesheetDialogComponent } from '../../components/timesheet-dialog/timesheet-dialog.component';
import { MatDialog } from '@angular/material/dialog';

type timesheetSummary = {
  hoursCount: number;
  userId: string;
  userName: string;
  expectedHours?: number;
};

type expectedTimesheet = {
  expectedHours: number;
  userId: string;
};

@Component({
  selector: 'app-timesheets',
  standalone: true,
  imports: [
    ProfilePictureComponent,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    NotFoundComponent,
    LoadingScreenComponent,
  ],
  templateUrl: './timesheets.component.html',
  styleUrl: './timesheets.component.scss',
})
export class TimesheetsComponent implements OnInit {
  readonly dialog = inject(MatDialog);

  private schoolService = inject(SchoolService);
  protected apiService = inject(ApiService);

  unidades = this.schoolService.unidades();
  timesheet: timesheetSummary[] = [];

  schoolId = model<number | string>(this.unidades[0].id);
  readonly date = new FormControl(this.getMontAndYear());

  protected notFound: boolean = false;

  readonly endDate = new Date();

  ngOnInit(): void {
    this.getTimesheets();
  }

  getTimesheets() {
    const month = this.date.value?.split('/')[0];
    const year = this.date.value?.split('/')[1];

    const filters = {
      school_id: this.schoolId(),
      month,
      year,
    };

    this.apiService
      .getByFilters<timesheetSummary[]>('diario/summary', filters, true)
      .subscribe((summary) => {
        this.timesheet = summary;
        this.notFound = summary.length === 0;

        const isCurrentMonth = this.getMontAndYear() == `${month}/${year}`;

        if (isCurrentMonth) {
          this.apiService
            .getByFilters<expectedTimesheet[]>(
              'diario/expected-diary-entries',
              filters
            )
            .subscribe((expectation) => {
              expectation.forEach((e) => {
                const i = this.timesheet.findIndex((t) => t.userId == e.userId);
                if (i >= 0) this.timesheet[i].expectedHours = e.expectedHours;
              });
            });
        }
      });
  }

  openTimesheetDialog(summary: timesheetSummary): void {
    this.dialog.open(TimesheetDialogComponent, {
      maxWidth: '1000px',
      width: '1000px',
      data: {
        teacherId: summary.userId,
        name: summary.userName,
        date: this.date.value,
        school: this.unidades.find(u=>u.id == this.schoolId())?.name
      },
    });
  }

  setMonthAndYear(
    normalizedMonthAndYear: Date,
    datepicker: MatDatepicker<Date>
  ) {
    this.date.setValue(this.getMontAndYear(normalizedMonthAndYear));
    datepicker.close();
  }

  getMontAndYear(date?: Date): string {
    if (!date) date = new Date();

    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month}/${year}`;
  }
}
