import { Injectable } from '@angular/core';
import { studentRiskMap } from './mapa-de-risco.component';
import { BasePaginatedService } from '../../abstracts/base-paginated.service';

@Injectable({
  providedIn: 'root',
})
export class MapaDeRiscoService extends BasePaginatedService<studentRiskMap> {
  override resourceUrl: string = 'students/mapa_de_risco';
}
