import {
  Component,
  inject,
} from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { IColaborador } from '../../models/colaborador.model';
import { LoadingComponent } from '../../components/loading/loading.component';
import { ChipComponent } from '../../components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ColaboradorService } from '../../services/colaborador.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { MatDialog } from '@angular/material/dialog';
import { ColaboradorDialogComponent } from '../../components/colaborador-dialog/colaborador-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgClass } from '@angular/common';
import { BasePaginatedComponent } from '../../abstracts/base-paginated.component';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { MobileQueryService } from '../../services/mobile-query.service';
import { AuthService } from '../../services/auth.service';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';

@Component({
  selector: 'app-colaboradores',
  standalone: true,
  imports: [
    MatTableModule,
    ChipComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatPaginatorModule,
    NgClass,
    LoadingComponent,
    NotFoundComponent,
    LoadingScreenComponent,
    ProfilePictureComponent
  ],
  templateUrl: './colaboradores.component.html',
  styleUrl: './colaboradores.component.scss',
})
export class ColaboradoresComponent extends BasePaginatedComponent {

  protected mobileQueryService = inject(MobileQueryService);
  protected override paginatedService = inject(ColaboradorService);

  protected authService = inject(AuthService);
  
  readonly dialog = inject(MatDialog);

  displayedColumns: string[] = ['name', 'role', 'unit', 'email', 'status', 'actions'];
  eCargo = ECargo;

  protected colaboradorService = inject(ColaboradorService);

  edit(colaborador: IColaborador) {
    this.dialog.open(ColaboradorDialogComponent, {
      data: colaborador,
    });
  }
}