export function calcularTempoPassado(dataString: string): string {
  const dataPassada = new Date(dataString);
  const agora = new Date();
  const diffMs = agora.getTime() - dataPassada.getTime();

  // Cálculos de tempo
  const segundos = Math.floor(diffMs / 1000);
  const minutos = Math.floor(segundos / 60);
  const horas = Math.floor(minutos / 60);
  const dias = Math.floor(horas / 24);
  const semanas = Math.floor(dias / 7);
  const meses = Math.floor(dias / 30.44); // Média de dias por mês
  const anos = Math.floor(meses / 12);

  if (anos > 0) {
    return anos === 1 ? 'há 1 ano' : `há ${anos} anos`;
  } else if (meses > 0) {
    return meses === 1 ? 'há 1 mês' : `há ${meses} meses`;
  } else if (semanas > 0) {
    return semanas === 1 ? 'há 1 semana' : `há ${semanas} semanas`;
  } else if (dias > 0) {
    return dias === 1 ? 'há 1 dia' : `há ${dias} dias`;
  } else if (horas > 0) {
    return horas === 1 ? 'há 1 hora' : `há ${horas} horas`;
  } else if (minutos > 0) {
    return minutos === 1 ? 'há 1 minuto' : `há ${minutos} minutos`;
  } else {
    return 'há poucos segundos';
  }
}
