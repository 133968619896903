@if(notificationService.isLoading()){
<div [ngClass]="isNotificationView?'w-full h-full':'w-full p-20'">
    <app-loading-screen></app-loading-screen>
</div>
}
@else{
<div class="flex flex-col">

    @for (notification of notificationService.notifications(); track notification){

    @let isExternalLink = notification.link.indexOf("https://") == 0;


    <a mat-menu-item class="rounded-lg" [attr.href]="notification.link"
        [attr.target]="isExternalLink ? '_blank' : '_self' ">

        <div class="grid grid-flow-col columns-auto gap-4 items-center py-4 md:p-4">

            @if(notification.triggeredBy) {
                <app-profile-picture [user]="{id: notification.triggeredBy}" size="medium"></app-profile-picture>
            }
            @else {
                <div>
                    <img src="assets/silver-logo.png" alt="USK Profile Picture" class="w-10">
                </div>
            }

            <div class="flex flex-col">
                <span class="font-normal text-light">{{notification.message}}</span>
            </div>
        </div>

    </a>

    }
    @empty {
    <button mat-menu-item class="w-full">
        <mat-icon>info_outline</mat-icon>
        <span class="font-normal text-light">Você não tem notificações</span>
    </button>
    }

</div>
}