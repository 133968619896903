export function randomInt(from: number, to: number): number {
  return Math.floor(Math.random() * (to - from + 1)) + from;
}


export function getColorFromString(input: string): 'yellow' | 'green' | 'orange' | 'blue' | 'red' | 'purple' {
  const colors: Array<'yellow' | 'green' | 'orange' | 'blue' | 'red' | 'purple' > = [
    'green', 'yellow', 'orange', 'blue', 'red', 'purple'
  ];

  // Create a hash code based on the input string
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Use the hash code to select a color
  const index = Math.abs(hash) % colors.length;
  return colors[index];
}