@if (diaryService.isLoading) {
<div class="card w-full h-[23rem] flex items-center justify-center">
  <app-loading [shape]="'circle'"></app-loading>
</div>
}
@else {

<div class="card">

  <table mat-table [dataSource]="diaryService.diaries" class="table !shadow-none">

    <ng-container matColumnDef="day">
      <th mat-header-cell *matHeaderCellDef class="text-nowrap">
        <mat-icon class="mat-18">calendar_month</mat-icon> {{diaryService.monthName}}
      </th>
      <td mat-cell *matCellDef="let class" style="font-family: monospace;" class="text-light">

        <div class="flex flex-row gap-2 items-center">

          <div class="rounded-default py-1 items-center justify-center flex"
            [ngClass]="{'bg-blue-500 text-white px-2 -ml-2 shadow-md':class.isToday}">
            <span class="!font-bold mr-2">
              {{getDayOfWeek(class.date).split(' ')[0]}}
            </span>
            <span>
              {{getDayOfWeek(class.date).split(' ')[1]}}
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef> Class Type </th>
    <td mat-cell *matCellDef="let class"> {{class.type ? enumToString(eClassType, class.type) : '--'}} </td>
  </ng-container> -->

    <ng-container matColumnDef="paragraph">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Paragraph </th>
      <td mat-cell *matCellDef="let diario" class="max-md:hidden">
        @if(diario.realizado){
          {{diario.realizado.paragraph ?? '--'}}
        } @else {
          <span class="text-light italic">
            {{diario.previsto?.paragraph ?? '--'}}
          </span>
        }
        </td>
    </ng-container>

    <ng-container matColumnDef="lastWord">
      <th mat-header-cell *matHeaderCellDef> Last Word </th>
      <td mat-cell *matCellDef="let diario">
        @if(diario.realizado){
          {{diario.realizado.lastWord ?? '--'}}
        } @else {
          <span class="text-light italic">
            {{diario.previsto?.lastWord ?? '--'}}
          </span>
        }
       </td>
    </ng-container>

    <ng-container matColumnDef="reading">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon class="mat-18">menu_book</mat-icon>
        Reading
      </th>
      <td mat-cell *matCellDef="let diario" class="max-md:hidden">
        @if(diario.realizado){
          {{diario.realizado.reading ?? '--'}}
        } @else {
          <span class="text-light italic">
            {{diario.previsto?.reading ?? '--'}}
          </span>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="dictation">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon class="mat-18">edit</mat-icon>
        Dictation
      </th>
      <td mat-cell *matCellDef="let diario" class="max-md:hidden"> 
        @if(diario.realizado){
          {{diario.realizado.dictation ?? '--'}}
        } @else {
          <span class="text-light italic">
            {{diario.previsto?.dictation ?? '--'}}
          </span>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="teacher">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        Teacher
      </th>
      <td mat-cell *matCellDef="let class" class="max-md:hidden"> {{class.realizado?.teacherName ?? '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        Status
      </th>
      <td mat-cell *matCellDef="let class" class="max-md:hidden">
        @if(class.status) {
          <chip [color]="getEnumValue(eClassStatusColor, class.status)">
            {{enumToString(eClassStatus, class.status)}}
          </chip>
        }
        @else {
          --
        }
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let class; columns: displayedColumns;" (click)="openDialog(class)"></tr>
  </table>

  <div class="flex flex-row justify-end items-center border-t border-gray-300 py-2 pr-2 pl-5">

    
    <div class="flex flex-row items-center justify-end">
      @if (diaryService.diaries.length > 0) {
        <p class="text-xs !mx-3 text-light">
          {{diaryService.monthName}}
          {{getDayOfWeek(diaryService.diaries[0].date).split(' ')[1]}}
          <span>-</span>
          {{getDayOfWeek(diaryService.diaries[4].date).split(' ')[1]}}
        </p>
      }
      
      <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="paginate(-1)">
        <mat-icon>chevron_left</mat-icon>
      </button>
      
      
      <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="paginate(1)">
        <mat-icon>chevron_right</mat-icon>
      </button>
      
      @if (authService.hasRole([authService.roles.Adm, authService.roles.Coordenador])) {
        <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="openPlanejamento()">
          <mat-icon>settings</mat-icon>
        </button>
      } 
    </div>
  </div>

</div>
}