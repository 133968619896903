import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from './api.service';
import { tap } from 'rxjs';

export type notification = {
  title: string;
  message: string;
  timestamp: string;
  triggeredBy?: string;
  link: string;
  read: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private apiService = inject(ApiService);
  public notifications = signal<notification[]>([]);
  public unread = signal<number>(0);

  public isLoading = signal<boolean>(false);

  getAll() {
    this.isLoading.set(true);

    return this.apiService
      .get<notification[]>('/notifications/', undefined, false)
      .pipe(
        tap((notifications) => {
          this.notifications.set(notifications);
          this.isLoading.set(false);
          this.unread.set(0);
        })
      );
  }

  countUnread() {
    return this.apiService
      .get<{ count: number }>('/notifications/count_unread', undefined, false)
      .pipe(
        tap((response) => {
          this.unread.set(response.count);
        })
      );
  }
}
