<div class="flex flex-row justify-between p-4 items-center">
  <h6 class="!ml-8">Matrícula</h6>

  <button mat-icon-button mat-dialog-close>
    <mat-icon class="mat-18">close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <mat-stepper #stepper [linear]="true" [orientation]="mobileQueryService.matches ? 'vertical' : 'horizontal'">

    <mat-step [stepControl]="alunoForm.personalInfoForm">

      <ng-template matStepLabel>Aluno</ng-template>
      <app-pessoa-form #alunoForm (onBirthdayChange)="onStudentBirthdayChange()"></app-pessoa-form>

    </mat-step>

    <mat-step [stepControl]="respForm.personalInfoForm">

      <ng-template matStepLabel>Responsável</ng-template>

      <div class="mb-8 mt-4 -ml-2">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="responsavelType"
          (ngModelChange)="updateResponsavelType($event)">

          <mat-radio-button [value]="EResponsavelType.PROPRIO_ALUNO" [disabled]="!isStudentAdult()">
            Próprio Aluno
          </mat-radio-button>
          <mat-radio-button [value]="EResponsavelType.PESSOA_FISICA">Pessoa Física</mat-radio-button>
          <mat-radio-button [value]="EResponsavelType.PESSOA_JURIDICA">Pessoa Jurídica</mat-radio-button>
        </mat-radio-group>
      </div>

      <app-pessoa-form #respForm></app-pessoa-form>

    </mat-step>
<!-- 
    <mat-step [stepControl]="financeiroForm.formGroup">

      <ng-template matStepLabel>Financeiro</ng-template>
      <app-financeiro-form #financeiroForm></app-financeiro-form>

    </mat-step> -->

  </mat-stepper>

</mat-dialog-content>

<mat-dialog-actions>
  @let isFirstStep = stepper.selectedIndex === 0;

  @if(!isFirstStep){
  <button mat-button (click)="stepper.previous()">Voltar</button>
  }

  @let isLastStep = stepper.selectedIndex === (stepper.steps.length - 1);

  @if(isLastStep){
  <button mat-button (click)="finalizarMatricula()" [disabled]="stepper.selected?.stepControl?.invalid">Finalizar Matrícula</button>
  }
  @else {
  <button mat-button (click)="stepper.next()" [disabled]="stepper.selected?.stepControl?.invalid">Próximo</button>
  }
</mat-dialog-actions>