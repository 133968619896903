@if(tarefasService.isLoading()){
<app-loading-screen></app-loading-screen>
}

<div [ngClass]="{'hidden': tarefasService.isLoading()}" class="flex flex-col md:flex-row-reverse gap-8">

    <div class="flex flex-col gap-4">

        <h1>
            <mat-icon class="align-middle mr-2">filter_list</mat-icon>
            Filtrar
        </h1>

        <mat-form-field appearance="outline" class="no-padding">
            <mat-label>
                Tags
            </mat-label>
            <mat-select [formControl]="tags" multiple (closed)="onFilter()">
                <mat-select-trigger>
                    {{tags.value?.[0] || ''}}
                    @if ((tags.value?.length || 0) > 1) {
                    <span class="example-additional-selection">
                        (+{{(tags.value?.length || 0) - 1}} {{tags.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                    }
                </mat-select-trigger>
                @for (option of toppingList; track option) {
                @if(option != 'desenvolvimento' || authService.hasRole(authService.roles.Adm)){
                <mat-option [value]="option">
                    <span class="capitalize">{{option}}</span>
                </mat-option>
                }
                }
            </mat-select>
        </mat-form-field>

    </div>


    <div class="grid md:grid-cols-3 gap-8 w-full md:w-10/12">

        @for(status of statuses; track $index){
        <div class="flex flex-col gap-4">

            <div class="flex flex-row gap-4 items-center">

                <h1>
                    <div class="status-dot" [ngClass]="'status-dot-'+status.id"></div>
                    {{status.name}}
                </h1>

                <button mat-button (click)="openTarefaDialog(status.id)">
                    <mat-icon>add</mat-icon> Novo
                </button>
            </div>

            <div class="flex flex-col gap-4 min-h-full" [id]="getListId(status.id)"
                [cdkDropListConnectedTo]="getListConnectedTo(status.id)" cdkDropList
                [cdkDropListData]="getListData(status.id)" (cdkDropListDropped)="drop($event)">

                @for(task of tarefasService.tasks; track $index){

                @let isAdm = authService.hasRole(authService.roles.Adm);

                @if(task.status_id == status.id && (!task.tags.includes("desenvolvimento") || isAdm)){

                <button [id]="'task-'+task.id" class="card p-4 flex flex-col gap-4 card-clickable" cdkDrag
                    (click)="openTarefaPreviewDialog(task)">

                    <div class="flex flex-row justify-between w-full">
                        <div class="flex flex-col gap-4 items-start text-left">

                            @if(task.tags.length){
                            <div>
                                @for (tag of task.tags; track tag){
                                <chip [color]="getColorFromString(tag)" class="mr-2 capitalize">{{tag}}</chip>
                                }
                            </div>
                            }

                            <p>{{task.title}}</p>
                        </div>
                        <div cdkDragHandle class="cursor-move">
                            <mat-icon>drag_indicator</mat-icon>
                        </div>
                    </div>

                    <div class="flex flex-row justify-between w-full">

                        <div class="flex flex-row gap-4 text-light">

                            <!-- <div class="flex flex-row items-center gap-1">
                                <mat-icon>attach_file</mat-icon>
                                <span>0</span>
                            </div> -->

                            <div class="flex flex-row items-center gap-1">
                                <mat-icon>chat_bubble_outline</mat-icon>
                                <span>{{task.comments_count}}</span>
                            </div>
                        </div>

                        <div class="flex flex-row gap-2">
                            @for (assignee of task.assignees; track assignee; let i = $index) {
                            @if(i <= 2){ <app-profile-picture [user]="assignee" size="small"></app-profile-picture>
                                }
                                }
                        </div>
                    </div>

                </button>
                }
                }
            </div>

        </div>
        }

    </div>

</div>