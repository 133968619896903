<h2 mat-dialog-title>Provas Realizadas - {{data.stageName}}</h2>
<mat-dialog-content>

    <table mat-table [dataSource]="examsReport">

        <ng-container matColumnDef="student">
            <th mat-header-cell *matHeaderCellDef> Aluno </th>
            <td mat-cell *matCellDef="let exam" class="text-nowrap">
                {{exam.full_name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef> Pontuação </th>
            <td mat-cell *matCellDef="let exam">
                <a mat-button [attr.href]="api.endpoints.frontend + '/stage/1/exam/' + exam.exam_id + '/score?role=adm'"
                    target="_blank" rel="noopener noreferrer">
                    <mat-icon class="mat-18">search</mat-icon>
                    {{exam.score}}
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Realização </th>
            <td mat-cell *matCellDef="let exam" class="text-nowrap">
                {{exam.end_time.split(' ')[0]}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Fechar</button>
</mat-dialog-actions>