<form [formGroup]="personalInfoForm">

  <div class="md:grid md:grid-cols-3 gap-4 mt-4 max-md:flex max-md:flex-col">

    <p class="md:col-span-full font-medium">Informações Pessoais</p>

    <ng-container>

      <mat-form-field [appearance]="appearance" class="col-span-2">
        <mat-label>Nome completo</mat-label>
        <input matInput formControlName="name" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>CPF</mat-label>
        <input matInput formControlName="cpf" placeholder="000.000.000-00" mask="000.000.000-00" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Nascimento</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="birthdate" [readonly]="true" [max]="todayDate" [readonly]="isReadonly"
          (dateChange)="onBirthdayChange.emit()" placeholder="dd/mm/yyyy" required />
        <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="isReadonly"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>RG</mat-label>
        <input matInput formControlName="rg" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Filiação 1</mat-label>
        <input matInput formControlName="filiacao1" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Filiação 2</mat-label>
        <input matInput formControlName="filiacao2" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Profissão</mat-label>
        <input matInput formControlName="profissao" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Local de trabalho/escola</mat-label>
        <input matInput formControlName="local_trabalho_escola" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Escolaridade</mat-label>
        <input matInput formControlName="escolaridade" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance" >
        <mat-label>Gênero</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="m" [disabled]="isReadonly">Masculino</mat-option>
          <mat-option value="f" [disabled]="isReadonly">Feminino</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Estado civil</mat-label>
        <mat-select formControlName="estado_civil">
          <mat-option value="solteiro" [disabled]="isReadonly">Solteiro(a)</mat-option>
          <mat-option value="casado" [disabled]="isReadonly">Casado(a)</mat-option>
          <mat-option value="viuvo" [disabled]="isReadonly">Viúvo(a)</mat-option>
        </mat-select>
      </mat-form-field>

    </ng-container>

    <mat-divider class="col-span-full"></mat-divider>

    <p class="md:col-span-full font-medium">Naturalidade</p>

    <ng-container>

      <mat-form-field [appearance]="appearance">
        <mat-label>Cidade</mat-label>
        <input matInput formControlName="cidade_natal" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Estado</mat-label>
        <input matInput formControlName="estado_natal" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>País</mat-label>
        <input matInput formControlName="pais_natal" required [readonly]="isReadonly">
      </mat-form-field>

    </ng-container>

    <mat-divider class="col-span-full"></mat-divider>

    <p class="col-span-full font-medium">Contato e Endereço</p>

    <ng-container formGroupName="contatoEndereco">


      <mat-form-field [appearance]="appearance">
        <mat-label>Telefone 1</mat-label>
        <input matInput formControlName="telefone1" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Telefone 2</mat-label>
        <input matInput formControlName="telefone2">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>E-mail</mat-label>
        <input matInput formControlName="email" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Rua</mat-label>
        <input matInput formControlName="rua" required [readonly]="isReadonly">
      </mat-form-field>

      <div class="flex flex-row gap-4">
        <mat-form-field [appearance]="appearance">
          <mat-label>Número</mat-label>
          <input matInput formControlName="numero" required [readonly]="isReadonly">
        </mat-form-field>

        <mat-form-field [appearance]="appearance">
          <mat-label>CEP</mat-label>
          <input matInput formControlName="cep" required [readonly]="isReadonly">
        </mat-form-field>
      </div>

      <mat-form-field [appearance]="appearance">
        <mat-label>Bairro</mat-label>
        <input matInput formControlName="bairro" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Cidade</mat-label>
        <input matInput formControlName="cidade" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Estado</mat-label>
        <input matInput formControlName="estado" required [readonly]="isReadonly">
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>País</mat-label>
        <input matInput formControlName="pais" required [readonly]="isReadonly">
      </mat-form-field>

    </ng-container>

  </div>
</form>