<h2 mat-dialog-title>
    Foto de perfil
</h2>

<mat-dialog-content>

    @if (previewUrl) {

    <img [src]="previewUrl" alt="User Profile Picture"
        class="w-44 aspect-square rounded-full object-cover object-center bg-gray-300 mx-auto">

    }@else {
    <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" />
    }

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
    @if(previewUrl){
    <button mat-flat-button (click)="onUpload()" [disabled]="!selectedFile || apiService.isLoading">Salvar</button>
    }
</mat-dialog-actions>