<mat-dialog-content>
    <table mat-table [dataSource]="dataSource" class="table !shadow-none">

        <ng-container matColumnDef="turma">
            <th mat-header-cell *matHeaderCellDef>
                <mat-icon>school</mat-icon>
                <span>Turma</span>
            </th>
            <td mat-cell *matCellDef="let turma"> {{turma.classroomName}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="!text-center">
                <span>Avaliação Semanal</span>
            </th>
            <td mat-cell *matCellDef="let turma" class="!text-center">
                @if(turma.weeklyAssessmentsComplete){
                <mat-icon class="text-green-600">check</mat-icon>
                }@else{
                <mat-icon class="text-red-600">close</mat-icon>
                }
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let turma; columns: displayedColumns;" [routerLink]="['turma', turma.classroomId]"
            mat-dialog-close></tr>

    </table>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Ok</button>
    <!-- <button mat-button mat-dialog-close cdkFocusInitial>Fechar</button> -->
</mat-dialog-actions>