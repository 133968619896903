import { Component, inject, input, output } from '@angular/core';
import {
  ETurmaStatus,
  ETurmaStatusColor,
  getTurmaStatusColor,
} from '../../enums/e-turma-status.enum';
import { IDiario } from '../../models/diario.model';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, NgClass } from '@angular/common';
import { ChipComponent } from '../chip/chip.component';
import { enumToString } from '../../utils/enum-to-string.util';
import { getDayOfWeek } from '../../utils/get-day-of-week.util';
import { EClassType } from '../../enums/e-class-type.enum';
import {
  EClassStatus,
  EClassStatusColor,
} from '../../enums/e-class-status.enum';
import { getEnumValue } from '../../utils/get-enum-value.util';
import { LoadingComponent } from '../loading/loading.component';
import { MatDialog } from '@angular/material/dialog';
import { DiaryDialogComponent } from '../diary-dialog/diary-dialog.component';
import { DiaryService } from '../../services/diary.service';
import { MatButtonModule } from '@angular/material/button';
import { PlanejamentoDialogComponent } from '../planejamento-dialog/planejamento-dialog.component';
import { AuthService } from '../../services/auth.service';
import { MobileQueryService } from '../../services/mobile-query.service';
import { RouterModule } from '@angular/router';
import { ECargo } from '../../enums/e-cargo.enum';

@Component({
  selector: 'app-diario',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    ChipComponent,
    NgClass,
    LoadingComponent,
    MatButtonModule,
    RouterModule
  ],
  templateUrl: './diario.component.html',
  styleUrl: './diario.component.scss',
})
export class DiarioComponent {
  protected enumToString = enumToString;
  protected eTurmaStatus = ETurmaStatus;
  protected eClassType = EClassType;
  protected eClassStatus = EClassStatus;
  protected eClassStatusColor = EClassStatusColor;
  protected eTurmaStatusColor = ETurmaStatusColor;

  protected getTurmaStatusColor = getTurmaStatusColor;
  protected getDayOfWeek = getDayOfWeek;
  protected getEnumValue = getEnumValue;

  displayedColumns: string[] = [
    'day',
    'paragraph',
    'lastWord',
    'reading',
    'dictation',
    'teacher',
    'status',
  ];

  // protected mobileQueryService = inject(MobileQueryService);
  protected diaryService = inject(DiaryService);
  protected authService = inject(AuthService);
  readonly dialog = inject(MatDialog);

  classroomId = input.required<string>();

  stageId = input<number>();

  edition = input<number>();

  pageIndex = 0;

  ngOnInit(): void {

    const classroom_id = this.classroomId();

    this.diaryService.load(classroom_id);
  }

  openDialog(diario: IDiario) {
    if(this.authService.hasRole([ECargo.Adm, ECargo.Coordenador, ECargo.Professor]))
      this.dialog.open(DiaryDialogComponent, {
        data: {
          stageId: this.stageId(),
          classroomId: this.classroomId(),
          date: diario.date,
          isToday: diario.isToday,
          paragraph: diario.realizado?.paragraph,
          dictation: diario.realizado?.dictation,
          reading: diario.realizado?.reading,
          lastWord: diario.realizado?.lastWord,
          teacherId: diario.realizado?.teacherId,
          previsto: diario.previsto
        },
      });
  }

  paginate(index: number) {
    const classroom_id = this.classroomId();

    this.pageIndex += index;

    if (typeof classroom_id == 'string')
      this.diaryService.load(classroom_id, this.pageIndex);
  }

  openPlanejamento(){
    this.dialog.open(PlanejamentoDialogComponent, {
      data:{
        estagio: this.stageId(),
        edition: this.edition(),
        classroomId: this.classroomId()
      }
    })
  }
}
