<h2 mat-dialog-title style="margin-left: -0.5rem;">
    {{data.title ?? 'Atenção'}}
</h2>

<mat-dialog-content>
    <p>{{data.message}}</p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
</mat-dialog-actions>