import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appHoldable]',
  standalone: true
})
export class HoldableDirective {
  @Input() holdThreshold: number = 1000; // Default hold time threshold (1 second)
  @Output() held: EventEmitter<void> = new EventEmitter(); // Emit if held for threshold

  private holding: boolean = false;
  private timeout: any;
  private startTime: number = 0;
  private screenWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onHoldStart(event: Event) {
    // Only run the hold logic if screen width is less than 768px
    if (this.screenWidth >= 768) return;

    // event.preventDefault();
    this.holding = true;
    this.startTime = Date.now();

    // Schedule the held event to emit after the holdThreshold time, if still holding
    this.timeout = setTimeout(() => {
      if (this.holding) {
        this.held.emit(); // Emit the held event after the threshold
        this.vibrate(50); // Optional: Vibrate for 200ms on successful hold
      }
    }, this.holdThreshold);

  }

  @HostListener('touchend')
  @HostListener('mouseup')
  onHoldEnd() {
    if (this.holding) {
      const holdDuration = Date.now() - this.startTime;
      this.holding = false;

      // Clear the timeout if the user releases early
      clearTimeout(this.timeout);
    }
  }

  private vibrate(duration: number) {
    if (navigator.vibrate) {
      navigator.vibrate(duration);
    }
  }
}
