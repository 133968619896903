<button [matMenuTriggerFor]="tableFilter" class="flex flex-row items-center">
    @if(formControl.value?.length){
    <mat-icon>filter_alt</mat-icon>
    }
    @else{
    <mat-icon class="text-light">filter_list</mat-icon>
    }
    {{label()}}
</button>

<mat-menu #tableFilter="matMenu" (close)="onFilter()">

    <div class="px-2">
        <mat-form-field (click)="$event.stopPropagation()" class="no-padding" appearance="outline">
            <mat-label>{{label()}}</mat-label>
            <mat-select [formControl]="formControl" multiple (closed)="tableFilter.close.emit()">
                @for (option of options(); track option) {
                <mat-option [value]="option.value">{{option.label}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>

</mat-menu>