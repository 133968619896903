import { inject, Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';

export type planejamento = {
  id: number;
  edition: number;
  estagio: number;
  materia: string;
  paragrafo?: number;
  reading?: number;
  dictation?: number;
  editMode?: boolean;
  order: number;
};

@Injectable({
  providedIn: 'root',
})
export class PlanejamentoService {
  private apiService = inject(ApiService);

  dataSource: MatTableDataSource<planejamento> = new MatTableDataSource();

  getByFilters(filter: { estagio: number; edition: number }) {
    this.apiService
      .get<planejamento[]>(
        `planejamento/${filter.estagio}/edition/${filter.edition}`
      )
      .subscribe((response) => {
        this.dataSource.data = response;
      });
  }

  upsert(item: planejamento) {

    delete item.editMode;

    return this.apiService.post('planejamento/', item);
  }

  delete(item: planejamento){
    return this.apiService.delete(`planejamento/${item.id}`)
  }
}
