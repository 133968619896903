import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../services/api.service';
import { IPaginatedResult } from '../../models/paginatedResult.model';
import { IAluno } from '../../models/aluno.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-student-addition-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
  ],
  templateUrl: './student-addition-dialog.component.html',
  styleUrl: './student-addition-dialog.component.scss',
})
export class StudentAdditionDialogComponent {
  options: { id: string; name: string }[] = [];
  alunoControl: FormControl<IAluno | null> = new FormControl(null);

  private apiService = inject(ApiService);
  protected isLoading: boolean = false;

  searchSubscription?: Subscription;

  readonly dialogRef = inject(MatDialogRef<StudentAdditionDialogComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);

  onSearch(): void {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();

    if (!this.alunoControl.value) {
      this.options = [];
      return;
    }

    this.searchSubscription = this.apiService
      .getByFilters<IPaginatedResult<IAluno>>('students/', {
        pageSize: 5,
        search: this.alunoControl.value,
      })
      .subscribe((response) => {
        this.options = response.items.map((student) => {
          return {
            id: student.id,
            name: student.name,
          };
        });
      });
  }

  addStudent() {
    if (!this.alunoControl.value) return;

    const studentId = this.alunoControl.value.id;

    this.isLoading = true;
    this.apiService
      .post('classrooms/add_student', {
        student_id: studentId,
        classroom_id: this.data.classroomId,
      })
      .subscribe(() => {
        this.isLoading = false;
        this.dialogRef.close(this.alunoControl.value);
      });
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }
}
