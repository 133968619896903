import { Component, inject, model, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StarsComponent } from '../../components/stars/stars.component';
import { MatDividerModule } from '@angular/material/divider';
import { ActivatedRoute } from '@angular/router';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { IColaborador } from '../../models/colaborador.model';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MessageService } from '../../services/message.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

type assessmentQuestion = {
  id: number;
  question_text: string;
  answer?: number;
};

type assessmentAnswer = {
  question_id: number;
  grade: number;
};

@Component({
  selector: 'app-teacher-assessment',
  standalone: true,
  imports: [
    StarsComponent,
    MatDividerModule,
    ProfilePictureComponent,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule
  ],
  templateUrl: './teacher-assessment.component.html',
  styleUrl: './teacher-assessment.component.scss',
})
export class TeacherAssessmentComponent implements OnInit {
  protected apiService = inject(ApiService);
  protected authService = inject(AuthService);
  protected questions: assessmentQuestion[] = [];
  private route = inject(ActivatedRoute);
  private messageService = inject(MessageService);

  protected teacher?: IColaborador;

  assessmentComplete: boolean = true;

  protected comment = model<string>()

  protected monthName?: string;
  protected month?: number;
  protected year?: number;

  filename?: string;

  ngOnInit(): void {
    const teacherId = this.route.snapshot.params['teacherId'];
    this.month = this.route.snapshot.params['month'];
    this.year = this.route.snapshot.params['year'];

    if(this.month)
      this.monthName = (new Date(0, this.month-1)).toLocaleString('default', { month: 'long' });

    this.filename = '2024_11_'+teacherId+'.mp4';

    this.apiService
      .getByFilters<{questions:assessmentQuestion[], comment:string}>('assessments/teachers/questions', {
        teacherId,
      })
      .subscribe((response) => {
        this.questions = response.questions;

        this.comment.set(response.comment)

        this.assessmentComplete = this.questions.every(
          (q) => q.answer != undefined
        );
      });

    this.apiService
      .get<IColaborador>(`users/${teacherId}`)
      .subscribe((user) => {
        this.teacher = user;
      });
  }

  answers: assessmentAnswer[] = [];

  onRate(answer: assessmentAnswer) {
    const existingAnswer = this.answers.find(
      (a) => a.question_id === answer.question_id
    );

    if (existingAnswer) Object.assign(existingAnswer, answer);
    else this.answers.push(answer);
  }

  onSubmit() {
    if (this.teacher == undefined) {
      return;
    }

    if (this.questions.length !== this.answers.length) {
      this.messageService.alert(
        'Responda todas as  perguntas antes de enviar a avaliação'
      );
      return;
    }

    this.assessmentComplete = true;

    this.apiService
      .post('assessments/teachers/questions', {
        teacher_id: this.teacher.id,
        answers: this.answers,
        comment: this.comment(),
        month: this.month,
        year: this.year,
      })
      .subscribe((response) => {
        this.messageService.celebrate();
      });
  }
}
