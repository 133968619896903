import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { map, Observable, startWith } from 'rxjs';
import { IAluno } from '../../models/aluno.model';
import { ApiService } from '../../services/api.service';
import { MessageService } from '../../services/message.service';

export interface User {
  name: string;
}

@Component({
  selector: 'app-student-removal-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogClose,
    MatDialogActions,
    MatDialogContent,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './student-removal-dialog.component.html',
  styleUrl: './student-removal-dialog.component.scss',
})
export class StudentRemovalDialogComponent implements OnInit {
  readonly data = inject<{
    student: IAluno;
    classroomId: string;
  }>(MAT_DIALOG_DATA);

  readonly dialogRef = inject(MatDialogRef<StudentRemovalDialogComponent>);

  protected apiService = inject(ApiService);
  private messageService = inject(MessageService);

  reasonControl = new FormControl<string | string>(''); // Change type to string
  removalReasons: string[] = [
    'Trancamento de curso',
    'Mudança de Escola',
    'Mudança de Turma',
    'Faltas Excessivas',
    'Comportamento Inadequado',
    'Motivos Pessoais',
    'Problemas de Adaptação',
  ];
  filteredOptions?: Observable<string[]>; // Change type to string[]

  ngOnInit() {
    this.reasonControl.addValidators(Validators.required);

    this.filteredOptions = this.reasonControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = value;
        return name
          ? this._filter(name as string)
          : this.removalReasons.slice();
      })
    );
  }

  displayFn(reason: string): string {
    return reason ? reason : '';
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();

    return this.removalReasons.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onRemoval() {
    this.apiService
      .post('classrooms/remove_student', {
        student_id: this.data.student.id,
        classroom_id: this.data.classroomId,
        reason: this.reasonControl.value,
      })
      .subscribe(() => {
        this.dialogRef.close(this.data.student.id);
        this.messageService.alert({
          title: "Confirmação",
          message: `${this.data.student.name} foi removido(a) com sucesso da turma.`
        })
      });
  }
}
