<div class="flex flex-col gap-2 p-6">

    <div class="flex flex-row justify-between items-center">
        <h6>{{data.title}}</h6>

        <div class="flex">
            <button mat-icon-button (click)="openUpdateDialog(data)">
                <mat-icon class="mat-18">edit</mat-icon>
            </button>

            <button mat-icon-button mat-dialog-close>
                <mat-icon class="mat-18">close</mat-icon>
            </button>
        </div>
    </div>

    <div>
        <div class="status-dot" [ngClass]="'status-dot-'+data.status_id"></div>
        {{status}}
    </div>

    <mat-divider></mat-divider>

</div>

<mat-dialog-content style="padding-top: 0;">

    <div class="flex flex-col gap-6">

        <div class="flex flex-col gap-4">

            @if(data.description) {
            <p class="font-medium">Descrição</p>

            <pre class="bg-gray-200 rounded p-4 whitespace-break-spaces " style="font-family: inherit;"
                [innerText]="data.description"></pre>
            }

            <p class="font-medium">Designado para</p>

            <div class="flex flex-row gap-2">
                @for (assignee of data.assignees; track assignee; let i = $index) {
                @if(i <= 2){ <app-profile-picture [user]="assignee" size="small"></app-profile-picture>
                    }
                    }
            </div>

        </div>


        <div class="flex flex-col gap-4">
            @if(data.comments_count == 1){
            <p class="font-medium">1 Comentário</p>
            }
            @else {
            <p class="font-medium">{{data.comments_count}} Comentários</p>
            }

            <div>
                <mat-form-field appearance="outline" class="w-full no-padding">
                    <mat-label>Adicione um comentário...</mat-label>
                    <textarea matInput class="!resize-none" [(ngModel)]="commentText"></textarea>
                </mat-form-field>

                @if(commentText()){
                <div class="flex flex-row gap-4 justify-end mt-4">
                    <button mat-button (click)="commentText.set('')">Cancelar</button>
                    <button mat-flat-button (click)="upsertComment()">Comentar</button>
                </div>
                }
            </div>

            <div class="flex flex-col gap-6" [ngClass]="{'hidden': taskComments.length === 0}">
                @for (comment of taskComments; track comment) {
                <div class="flex flex-row gap-2">
                    <app-profile-picture [user]="comment.user" size="small"></app-profile-picture>
                    <div class="flex flex-col gap-2">
                        <div class="flex flex-row gap-2 items-center">
                            <p class="font-medium">{{getShortName(comment.user.name)}}</p>
                            <p class="text-light text-xs">{{calcularTempoPassado(comment.created_at)}}</p>
                        </div>
                        <p>{{comment.content}}</p>
                    </div>
                </div>
                }
            </div>

        </div>

    </div>

</mat-dialog-content>