import { Component, inject, input, OnInit, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { range } from '../../utils/range.util';
import { NgClass } from '@angular/common';
import { MobileQueryService } from '../../services/mobile-query.service';

@Component({
  selector: 'app-stars',
  standalone: true,
  imports: [MatIconModule, NgClass],
  templateUrl: './stars.component.html',
  styleUrl: './stars.component.scss',
})
export class StarsComponent implements OnInit {

  protected mobileQueryService = inject(MobileQueryService)

  readonly = input<boolean>(false);
  grade = input<number>();
  max = input<number>(3);

  value: number = 0;

  change = output<number>();

  range: number[] = [];

  ngOnInit(): void {
    let grade = this.grade() ?? 0;

    this.value = grade;
    this.range = range(1, this.max());
  }

  changeGrade(value: number) {
    if (this.readonly()) return;

    this.value = value;
    this.change.emit(value);
  }
}
