import {
  Directive,
  ElementRef,
  AfterViewInit,
  input,
  inject,
  ChangeDetectorRef,
} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  appAutoFocus = input<boolean>(false);
  cd = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    if (this.appAutoFocus())
      // Use a timeout to ensure the focus works properly, especially on mobile devices.
      // On mobile, the virtual keyboard may take time to appear, and focusing immediately
      // on the input may fail due to rendering delays or layout shifts.
      setTimeout(() => {
        this.elementRef.nativeElement.focus();
      }, 500);

    this.cd.detectChanges();
  }
}
