<div class="flex flex-row items-center gap-4">
    @switch (size()) {
    @case ('small') {
    <img [src]="picUrl" alt="Profile Picture"
        class="rounded-full w-8 aspect-square object-cover object-center bg-gray-300" [matTooltip]="user().name">
    }
    @case ('medium') {
    <img [src]="picUrl" alt="Profile Picture"
        class="rounded-full w-10 aspect-square object-cover object-center bg-gray-300">
    }
    @case ('big') {
    <img [src]="picUrl" alt="Profile Picture"
        class="rounded-full w-20 aspect-square object-cover object-center bg-gray-300">
    }
    @default {
    <img [src]="picUrl" alt="Profile Picture"
        class="rounded-full w-12 aspect-square object-cover object-center bg-gray-300">
    }
    }

    @if(size() != 'small' && !hideName() && user().name){
    <ng-container *ngTemplateOutlet="userName"></ng-container>
    }

</div>

<ng-template #userName>
    <div class="flex flex-col">

        @let name = user().name?.toLowerCase();

        @if(shortenName()){
        <p class="font-medium text-nowrap capitalize">{{getShortName(name)}}</p>
        }
        @else {
        <p class="font-medium capitalize">{{name}}</p>
        }

        <ng-content></ng-content>
    </div>
</ng-template>