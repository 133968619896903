export enum ETurmaStatus {
  EM_FORMAÇÃO = 1,
  EM_ANDAMENTO = 2,
  FINALIZADA = 5,
}

export const ETurmaStatusColor: Record<ETurmaStatus, string> = {
  [ETurmaStatus.EM_FORMAÇÃO]: 'orange',
  [ETurmaStatus.EM_ANDAMENTO]: 'green',
  [ETurmaStatus.FINALIZADA]: 'red',
};

export function getTurmaStatusColor(status: ETurmaStatus) {
  return ETurmaStatusColor[status];
}
