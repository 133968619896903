export enum EClassStatus {
  ADIANTADO = 1,
  EM_DIA = 2,
  ATRASADO = 3,
}

export const EClassStatusColor: Record<EClassStatus, string> = {
  [EClassStatus.ADIANTADO]: 'blue',
  [EClassStatus.EM_DIA]: 'green',
  [EClassStatus.ATRASADO]: 'red',
};

export function getClassTypeColor(status: EClassStatus) {
  return EClassStatus[status];
}
