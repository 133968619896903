import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { BasePaginatedService } from '../../abstracts/base-paginated.service';
import { BasePaginatedComponent } from '../../abstracts/base-paginated.component';
import { arraysAreEqual } from '../../utils/compare-arrays.util';

export type tableFilterOptions = {
  value: string;
  label: string;
}[];

@Component({
  selector: 'app-table-filter',
  standalone: true,
  imports: [
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  templateUrl: './table-filter.component.html',
  styleUrl: './table-filter.component.scss',
})
export class TableFilterComponent {
  paginatedService = input.required<BasePaginatedService<any>>();
  paginatedComponent = input.required<BasePaginatedComponent>();
  options = input.required<tableFilterOptions>();
  label = input.required<string>();

  /**
   * The `name` variable represents the name value
   * that will be sent as the query parameter to the backend API.
   */
  name = input.required<string>();

  formControl: FormControl<string[] | null> = new FormControl([]);

  private previousFilter: string[] = [];

  onFilter() {
    if (this.formControl.value == null) return;

    if (!arraysAreEqual(this.previousFilter, this.formControl.value)) {
      this.paginatedService().filters[this.name()] = this.formControl.value;

      this.paginatedComponent()
        .loadData()
        .subscribe((response) => {
          this.paginatedService().onLoad(response);
          this.paginatedComponent().setUpPageIndexAndLength();
        });

      this.previousFilter = this.formControl.value;
    }
  }
}
