<div class="card max-w-screen-sm m-auto">

    <div class="p-4">
        @if(teacher){
        <app-profile-picture [user]="teacher">
            <p class="text-light">{{monthName}} {{year}} Assessment</p>
        </app-profile-picture>
        }
    </div>

    <div class="p-4">
        <mat-tab-group [headerPosition]="'below'">
            <mat-tab label="Revision">
                <video controls class="w-full aspect-video bg-gray-800">
                    <source [src]="apiService.endpoint + '/storage/stream_video/revision/' + filename" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </mat-tab>
            <mat-tab label="New Work">
                <video controls class="w-full aspect-video bg-gray-800">
                    <source [src]="apiService.endpoint + '/storage/stream_video/new_work/' + filename" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </mat-tab>
        </mat-tab-group>
    </div>

    @for(question of questions; track question; let i = $index){
    <div class="flex flex-row px-4 py-5 gap-4">

        <p class="text-light w-full">
            <span class="font-medium">{{i+1}})</span>
            {{question.question_text}}
        </p>

        <app-stars [grade]="question.answer" [readonly]="assessmentComplete"
            (change)="onRate({question_id:question.id, grade:$event})"></app-stars>
    </div>
    }

    @let hasRole = authService.hasRole([authService.roles.Adm, authService.roles.Coordenador]);

    @if(questions.length > 0){
    <div class="px-4 pt-4">
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>Comentário</mat-label>
            <textarea matInput placeholder="Deixe um comentário" rows="4" [(ngModel)]="comment" 
                [readonly]="assessmentComplete || !hasRole"></textarea>
        </mat-form-field>
    </div>
    }

    @if(!assessmentComplete && hasRole){
    <div class="p-4">
        <button mat-flat-button class="w-full" (click)="onSubmit()">
            Enviar Feedback
        </button>
    </div>
    }

</div>