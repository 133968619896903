import { inject, Injectable } from '@angular/core';
import { ITurma } from '../models/turma.model';
import { MatTableDataSource } from '@angular/material/table';
import { IAluno } from '../models/aluno.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePaginatedService } from '../abstracts/base-paginated.service';

@Injectable({
  providedIn: 'root',
})
export class TurmaService extends BasePaginatedService<ITurma> {

  override resourceUrl: string = 'classrooms/';

  // private apiService = inject(ApiService);
  public turmas = new MatTableDataSource<ITurma>([]);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  public students: IAluno[] = [];

  put(classroom: Partial<ITurma>) {
    this._isLoading = true;
    this.apiService.post('classrooms/', classroom).subscribe({
      next: (returnedClassroom) => {
        this._isLoading = false;
        setTimeout(() => {

          if (classroom.id) {
            const nameFilter = this.route.snapshot.queryParams['search'];  
            this.getAll(undefined, undefined, nameFilter).subscribe(this.onLoad.bind(this));
          } else { 
            this.router.navigate(['turma', returnedClassroom.id])
          }

        }, 0);
      },
      error: () => {
        this._isLoading = false;
      },
    });
  }

}